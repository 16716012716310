import PaywallIllustration from 'assets/images/paywall.png';
import { useEffect, useState } from 'react';
import { UPDATE_DELAY } from 'utils/constants';
import './transcription-status.scss';

export default function InProgress({ onCheckStatus }) {
  const [disableCheckStatusButton, setDisableCheckStatusButton] = useState(false);
  let timer = null;

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  const onCheckStatusClick = () => {
    setDisableCheckStatusButton(true);
    setTimeout(() => {
      setDisableCheckStatusButton(false);
    }, UPDATE_DELAY);
    onCheckStatus();
  };

  return (
    <div className="transcription-status">
      <div className="illustration">
        <img src={PaywallIllustration} alt="paywall papa bear" />
      </div>
      <div className="transcription-details">
        <div className="transcription-title">Transcription in Progress</div>
        <div className="transcription-description">
          Please wait for sometime, your transcription will be ready within minutes.
        </div>
        <button
          className="button primary"
          onClick={onCheckStatusClick}
          disabled={disableCheckStatusButton}
        >
          Check Status
        </button>
      </div>
    </div>
  );
}
