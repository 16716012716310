import { Link } from 'react-router-dom';
import _ from 'lodash';
import 'firebase/auth';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as LogoDark } from 'assets/logo-dark.svg';
import AccountMenu from 'components/account-menu/AccountMenu';
import './Header.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useUserDetails } from 'hooks/useUserDetails';
import { useAppState } from 'appstate';

export default function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const firebaseUser = useUserDetails();
  const [state] = useAppState();
  const {
    user: { user },
  } = state;

  const handlePageScroll = () => {
    window.pageYOffset > '64' ? setIsSticky(true) : setIsSticky(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', _.throttle(handlePageScroll, 100));
    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  });

  return (
    <section className={`header ${isSticky ? 'sticky' : ''}`}>
      <div className="header-wrapper">
        <Link to={`${firebaseUser ? '/dashboard' : '/'}`} className="logo">
          <LogoDark />
        </Link>
        {firebaseUser && user && (
          <div className="action-bar">
            <AccountMenu />
          </div>
        )}
      </div>
      <ToastContainer />
    </section>
  );
}
