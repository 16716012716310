export const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    if(document.getElementById(src)) return resolve(false);
    const script = document.createElement('script');
    script.src = src;
    script.id = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = (err) => {
      reject(err);
    };
    document.head.appendChild(script);
  });
};
