import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';

import Header from './components/header/Header';
import Dashboard from './views/dashboard/Dashboard';
import UploadRecording from './views/upload-recording/UploadRecording';
import Transcript from './views/transcript/Transcript';
import useGoogleAnalytics from './components/analytics/GoogleAnalytics';
import { ProtectedRouteElement } from './components/protectedRoutes/ProtectedRoutes';
import { LandingPage } from 'components/landingPage/LandingPage';
import { Terms, Privacy } from './views/article/Article';
import CookieConsentElement from 'components/cookieConsentElement/CookieConsentElement';
import NotFound from 'components/not-found/NotFound';

import './Site.scss';
import Login from 'views/login/Login';
import { setCookie } from 'react-use-cookie';
import { SHOW_TRANSLATION_COOKIE } from 'utils/constants';

const AppRoutes = () => {
  useGoogleAnalytics();
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/login" element={<Login />} />
      <Route exact path="/dashboard" element={<ProtectedRouteElement component={Dashboard} />} />
      <Route exact path="/upload" element={<ProtectedRouteElement component={UploadRecording} />} />
      <Route exact path="/transcript" element={<ProtectedRouteElement component={Transcript} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const Site = () => {
  useEffect(() => {
    const preferredTheme = localStorage.getItem('theme');
    if (preferredTheme) {
      document.documentElement.setAttribute('data-theme', preferredTheme);
    } else {
      const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
    }
  });

  return (
    <Router>
      <div className="site">
        <Header />
        <CookieConsentElement />
        <AppRoutes />
      </div>
    </Router>
  );
};

export default Site;
