import { Link } from 'react-router-dom';
import './Footer.scss';

export default function Footer(props) {
  return (
    <footer className={`footer ${props.stacked ? 'stacked' : ''}`}>
      <div className="footer-wrapper">
        <Link to="/privacy" className="footer-link">
          Privacy Policy
        </Link>
        <Link to="/terms" className="footer-link">
          Terms of Use
        </Link>
        <a className="footer-link" href="mailto:hello@transcrisp.com">
          Support
        </a>
      </div>
    </footer>
  );
}
