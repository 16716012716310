import { errorToast } from 'utils/toast';
import './Loader.scss';

export default function Loader({ msg, fullScreen, inline, error }) {
  // TODOi: Make message full screen loader
  // Replace circle with new one
  // TODOi: gets hidden behind a full screen loader 
  if (error) errorToast(error);

  if (fullScreen) {
    return <div className='loader'>
      <div className="loading-ff">
        {msg || `Loading&#8230;`}
      </div>
    </div>
  }
  return <div className={`loader ${!inline ? 'min-height-loader' : ''}`}>
    <div className='loader-sm' />
  </ div>
}
