import React, { useReducer } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Site from './Site';
import reportWebVitals from './reportWebVitals';
import { combineReducers, initialState } from 'reducers';
import { userReducer } from 'reducers/user';
import { AppStateProvider } from 'appstate';

const root = ReactDOM.createRoot(document.getElementById('root'));

const appReducers = combineReducers({
  user: userReducer,
});

root.render(
  <React.StrictMode>
    <AppStateProvider initialState={initialState} reducer={appReducers}>
      <Site />
    </AppStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
