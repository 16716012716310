import { Link } from 'react-router-dom';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';

import Footer from 'components/footer/Footer';

import './SiteNavigation.scss';
import useFilterType from 'hooks/useFilterType';
import { isMobile } from 'utils/common';

export default function SiteNavigation(props) {
  const filter = useFilterType(props.location);

  return (
    <section className="site-navigation">
      <div className="navigation-links-wrapper">
        <Link to="/upload" className="upload">
          <UploadIcon className="upload-icon icon" />
          <span className="label">Upload your recording</span>
        </Link>
        <div className="divider"></div>
        <div className="organization">
          <Link to="/dashboard" className={`item ${filter === '' ? 'selected' : ''}`}>
            <FolderIcon className="icon" />
            <div className="label">All Recordings</div>
          </Link>
          <Link
            to="/dashboard?filter=starred"
            className={`item starred ${filter === 'starred' ? 'selected' : ''}`}
          >
            <StarIcon className="icon" />
            <div className="label">Starred</div>
          </Link>
          <Link
            to="/dashboard?filter=archived"
            className={`item archived ${filter === 'archived' ? 'selected' : ''}`}
          >
            <ArchiveIcon className="icon" />
            <div className="label">Archived</div>
          </Link>
        </div>
      </div>
      {!isMobile() && <Footer stacked />}
    </section>
  );
}
