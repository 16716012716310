// Pricing and Currency - Change on Server
export const PAYMENT_OPTIONS = {
  US: {
    ACTUAL_PRICE: 14.99,
    PRICE_PER_DURATION: 7.49,
    PRICE_DURATION: 60,
    CURRENCY: 'USD',
  },
  IN: {
    ACTUAL_PRICE: 1199,
    PRICE_PER_DURATION: 599,
    PRICE_DURATION: 60,
    CURRENCY: 'INR',
  },
};

export const DEFAULT_COUNTRY_CODE = 'US';
export const COUNTRY_CODE_IN = 'IN';

export const DEFAULT_TRANSCRIPTION_LANGUAGE = 'en-US';

export const DEFAULT_TRANSLATION_LANGUAGE = 'en';

export const DEFAULT_BILLING_ADDRESS = {
  line1: '',
  line2: '',
  postal_code: '',
  city: '',
  state: '',
  country: '',
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

export const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;

export const googleOneTabClientId = process.env.REACT_APP_GOOGLE_ONE_TAB_LOGIN_CLIENT_ID;

export const razorpayKeyId = process.env.REACT_APP_RAZORPAY_KEY;

export const cannyAppId = process.env.REACT_APP_CANNY_APP_ID;

export const RAZOR_PAY_SCRIPT_SRC = 'https://checkout.razorpay.com/v1/checkout.js';

export const GOOGLE_ONE_TAP_SCRIPT_SRC = 'https://accounts.google.com/gsi/client';

export const CANNY_SCRIPT_SRC = 'https://canny.io/sdk.js';

export const FEEDBACK_URL = 'https://feedback.transcrisp.com/feedback';

export const URL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:5050/api'
    : `${window.location.origin}/api`;

export const JOB_STATUS = {
  COMPLETED: 'COMPLETED',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
};

export const JOB_STATUS_MESSAGE = {
  [JOB_STATUS.COMPLETED]: 'Transcribed',
  [JOB_STATUS.NOT_SUBMITTED]: 'Not Transcribed',
  [JOB_STATUS.IN_PROGRESS]: 'Transcribing in progress',
  [JOB_STATUS.FAILED]: 'Failed',
};

export const UPDATE_DELAY = 30000; // in MS

export const excludedUrlSearchParamsTrackingGA = ['oid'];

export const CONSENT_COOKIE_NAME = 'cookie_consent';
export const CONSENT_PREFERENCE_COOKIE_NAME = 'cookie_consent_preference';

// Change size and duration limit on Server as well when changing this
export const MAX_FILE_UPLOAD_SIZE = 2 * 104857600; // 2*100 MB (100 MB = 104857600 bytes)
export const MAX_RECORDING_UPLOAD_DURATION = 120 * 60; // Seconds 120 mins
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY;

export const MAX_SPEAKERS_ALLOWED = 10;
