import Footer from 'components/footer/Footer';
import './Article.scss';

const Privacy = () => {
  return (
    <section id="privacy" className="article">
      <div className="container">
        <h1>Privacy Policy</h1>
        <p className="date">Effective date: 1st August, 2022</p>
        <h3>1. Introduction</h3>
        <p>
          Transcrisp operated by{' '}
          <a href="https://one9.tech" target="_blank">
            One9 Tech.
          </a>{' '}
          ("us", "we", or "our") operates{' '}
          <a href="https://transcrisp.com" target="_blank">
            https://transcrisp.com
          </a>{' '}
          (hereinafter referred to as "Service").
        </p>
        <p>
          Our Privacy Policy governs your visit to{' '}
          <a href="https://transcrisp.com" target="_blank">
            https://transcrisp.com
          </a>
          , and explains how we collect, safeguard and disclose information that results from your
          use of our Service.
        </p>
        <p>
          We use your data to provide and improve Service. By using Service, you agree to the
          collection and use of information in accordance with this policy. Unless otherwise defined
          in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions.
        </p>
        <p>
          Our Terms and Conditions ("Terms") govern all use of our Service and together with the
          Privacy Policy constitutes your agreement with us ("agreement").
        </p>
        <h3>2. Definitions</h3>
        <p>
          <b>SERVICE</b> means the{' '}
          <a href="https://transcrisp.com" target="_blank">
            https://transcrisp.com
          </a>{' '}
          website operated by us.
        </p>
        <p>
          <b>PERSONAL DATA</b> means data about a living individual who can be identified from those
          data (or from those and other information either in our possession or likely to come into
          our possession).
        </p>
        <p>
          <b>USAGE DATA</b> is data collected automatically either generated by the use of Service
          or from Service infrastructure itself (for example, the duration of a page visit).
        </p>
        <p>
          <b>COOKIES</b> are small files stored on your device (computer or mobile device).
        </p>
        <p>
          <b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in
          common with other persons) determines the purposes for which and the manner in which any
          personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are
          a Data Controller of your data.
        </p>
        <p>
          <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal person who
          processes the data on behalf of the Data Controller. We may use the services of various
          Service Providers in order to process your data more effectively.
        </p>
        <p>
          <b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.
        </p>
        <p>
          <b>THE USER</b> is the individual using our Service. The User corresponds to the Data
          Subject, who is the subject of Personal Data.
        </p>
        <h3>3. Information Collection and Use</h3>
        <p>
          We collect several different types of information for various purposes to provide and
          improve our Service to you.
        </p>
        <h3>4. Types of Data Collected</h3>
        <p>
          <b>Personal Data</b>
        </p>
        <p>
          While using our Service, we may ask you to provide us with certain personally identifiable
          information that can be used to contact or identify you ("Personal Data"). Personally
          identifiable information may include, but is not limited to:
        </p>
        <p>( a ) Email address</p>
        <p>( b ) Cookies and Usage Data</p>
        <p>
          We may use your Personal Data to contact you with newsletters, marketing or promotional
          materials and other information that may be of interest to you. You may opt out of
          receiving any, or all, of these communications from us by emailing at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>.
        </p>
        <p>
          <b>Usage Data</b>
        </p>
        <p>
          We may also collect information that your browser sends whenever you visit our Service or
          when you access Service by or through a mobile device ("Usage Data").
        </p>
        <p>
          This Usage Data may include information such as your computer's Internet Protocol address
          (e.g. IP address), browser type, browser version, the pages of our Service that you visit,
          the time and date of your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          When you access Service with a mobile device, this Usage Data may include information such
          as the type of mobile device you use, your mobile device unique ID, the IP address of your
          mobile device, your mobile operating system, the type of mobile Internet browser you use,
          unique device identifiers and other diagnostic data.
        </p>
        <p>
          <b>Tracking Cookies Data</b>
        </p>
        <p>
          We use cookies and similar tracking technologies to track the activity on our Service and
          we hold certain information.
        </p>
        <p>
          Cookies are files with a small amount of data which may include an anonymous unique
          identifier. Cookies are sent to your browser from a website and stored on your device.
          Other tracking technologies are also used such as tags and scripts to collect and track
          information and to improve and analyze our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being
          sent. However, if you do not accept cookies, you may not be able to use some portions of
          our Service.
        </p>
        <p>Examples of Cookies we use:</p>
        <p>( a ) Session Cookies: We use Session Cookies to operate our Service.</p>
        <p>
          ( b ) Preference Cookies: We use Preference Cookies to remember your preferences and
          various settings.
        </p>
        <p>( c ) Security Cookies: We use Security Cookies for security purposes.</p>
        <h3>5. Use of Data</h3>
        <p>Transcrisp uses the collected data for various purposes:</p>
        <p>( a ) to provide and maintain our Service;</p>
        <p>( b ) to notify you about changes to our Service;</p>
        <p>( c ) to provide customer support;</p>
        <p>( d ) to gather analysis or valuable information so that we can improve our Service;</p>
        <p>( e ) to monitor the usage of our Service;</p>
        <p>( f ) to detect, prevent and address technical issues;</p>
        <p>( g ) to fulfill any other purpose for which you provide it;</p>
        <p>
          ( h ) to carry out our obligations and enforce our rights arising from any contracts
          entered into between you and us, including for billing and collection;
        </p>
        <p>
          ( i ) to provide you with notices about your account and/or subscription, including
          expiration and renewal notices, email-instructions, etc.;
        </p>
        <p>
          ( j ) to provide you with news, special offers and general information about other goods,
          services and events which we offer that are similar to those that you have already
          purchased or enquired about unless you have opted not to receive such information;
        </p>
        <p>( k ) for any other purpose with your consent.</p>
        <h3>6. Retention of Data</h3>
        <p>
          We will retain your Personal Data only for as long as is necessary for the purposes set
          out in this Privacy Policy. We will retain and use your Personal Data to the extent
          necessary to comply with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
        </p>
        <p>
          We will also retain Usage Data for internal analysis purposes. Usage Data is generally
          retained for a shorter period, except when this data is used to strengthen the security or
          to improve the functionality of our Service, or we are legally obligated to retain this
          data for longer time periods.
        </p>
        <h3>7. Access, Correction and Deletion of Information</h3>
        <p>
          You can request for us to remove or modify any information about your account. To
          permanently remove your data, you must email us at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>, and we will remove your
          data within 30 days of notice.
        </p>
        <h3>8. Transfer of Data</h3>
        <p>
          Your information, including Personal Data, may be transferred to – and maintained on –
          computers located outside of your state, province, country or other governmental
          jurisdiction where the data protection laws may differ from those of your jurisdiction.
        </p>
        <p>
          If you are located in European Union and choose to provide information to us, please note
          that we transfer the data, including Personal Data, outside of European Union and process
          it there.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of such information
          represents your agreement to that transfer.
        </p>
        <p>
          We will take all the steps reasonably necessary to ensure that your data is treated
          securely and in accordance with this Privacy Policy and no transfer of your Personal Data
          will take place to an organisation or a country unless there are adequate controls in
          place including the security of your data and other personal information.
        </p>
        <h3>9. Disclosure of Data</h3>
        <p>
          We may disclose personal information that we collect, or you provide to fulfill the
          purpose for which you provide it.
        </p>
        <h3>10. Security of Data</h3>
        <p>
          The security of your data is important to us but remember that no method of transmission
          over the Internet or method of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Data, we cannot guarantee its
          absolute security.
        </p>
        <h3>11. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h3>
        <p>
          If you are a resident of the European Union (EU) and European Economic Area (EEA), you
          have certain data protection rights, covered by GDPR. – See more at{' '}
          <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" target="_blank">
            https://eur-lex.europa.eu/eli/reg/2016/679/oj
          </a>
        </p>
        <p>
          We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use
          of your Personal Data.
        </p>
        <p>
          If you wish to be informed what Personal Data we hold about you and if you want it to be
          removed from our systems, please email us at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>.
        </p>
        <p>In certain circumstances, you have the following data protection rights:</p>
        <p>( a ) the right to access, update or to delete the information we have on you;</p>
        <p>
          ( b ) the right of rectification. You have the right to have your information rectified if
          that information is inaccurate or incomplete;
        </p>
        <p>
          ( c ) the right to object. You have the right to object to our processing of your Personal
          Data;
        </p>
        <p>
          ( d ) the right of restriction. You have the right to request that we restrict the
          processing of your personal information;
        </p>
        <p>
          ( e ) the right to data portability. You have the right to be provided with a copy of your
          Personal Data in a structured, machine-readable and commonly used format;
        </p>
        <p>
          ( f ) the right to withdraw consent. You also have the right to withdraw your consent at
          any time where we rely on your consent to process your personal information;
        </p>
        <p>
          Please note that we may ask you to verify your identity before responding to such
          requests. Please note, we may not able to provide Service without some necessary data.
        </p>
        <p>
          You have the right to complain to a Data Protection Authority about our collection and use
          of your Personal Data. For more information, please contact your local data protection
          authority in the European Economic Area (EEA).
        </p>
        <h3>
          12. Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)
        </h3>
        <p>
          CalOPPA is the first state law in the nation to require commercial websites and online
          services to post a privacy policy. The law’s reach stretches well beyond California to
          require a person or company that operates websites collecting personally identifiable
          information from California consumers to post a conspicuous privacy policy on its website
          stating exactly the information being collected and those individuals with whom it is
          being shared, and to comply with this policy. – See more at:{' '}
          <a
            href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
            target="_blank"
          >
            https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
          </a>
        </p>
        <p>According to CalOPPA we agree to the following:</p>
        <p>( a ) users can visit our site anonymously;</p>
        <p>
          ( b ) our Privacy Policy link includes the word "Privacy", and can easily be found on the
          page specified above on the home page of our website;
        </p>
        <p>
          ( c ) users will be notified of any privacy policy changes on our Privacy Policy Page;
        </p>
        <p>
          ( d ) users are able to change their personal information by emailing us at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>.
        </p>
        <h3>13. Analytics</h3>
        <p>
          We may use third-party Service Providers to monitor and analyze the use of our Service.
        </p>
        <p>
          <b>Google Analytics</b>
        </p>
        <p>
          Google Analytics is a web analytics service offered by Google that tracks and reports
          website traffic. Google uses the data collected to track and monitor the use of our
          Service. This data is shared with other Google services. Google may use the collected data
          to contextualise and personalise the ads of its own advertising network.
        </p>
        <p>
          For more information on the privacy practices of Google, please visit the Google Privacy
          Terms web page:{' '}
          <a href="https://policies.google.com/privacy?hl=en" target="_blank">
            https://policies.google.com/privacy?hl=en
          </a>
        </p>
        <p>
          We also encourage you to review the Google's policy for safeguarding your data:{' '}
          <a href="https://support.google.com/analytics/answer/6004245" target="_blank">
            https://support.google.com/analytics/answer/6004245
          </a>
          .
        </p>
        <h3>14. Payments</h3>
        <p>
          We may provide paid products and/or services within Service. In that case, we use
          third-party services for payment processing (e.g. payment processors).
        </p>
        <p>
          We will not store or collect your payment card details. That information is provided
          directly to our third-party payment processors whose use of your personal information is
          governed by their Privacy Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of
          brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help
          ensure the secure handling of payment information.
        </p>
        <p>The payment processor we work with is:</p>
        <p>
          <b>Razorpay</b>:
        </p>
        <p>
          Their Privacy Policy can be viewed at{' '}
          <a href="https://razorpay.com/privacy/" target="_blank">
            https://razorpay.com/privacy/
          </a>
        </p>
        <p>
          <b>Stripe</b>:
        </p>
        <p>
          Their Privacy Policy can be viewed at{' '}
          <a href="https://stripe.com/en-in/privacy" target="_blank">
            https://stripe.com/en-in/privacy
          </a>
        </p>
        <h3>15. Links to Other Sites</h3>
        <p>
          Our Service may contain links to other sites that are not operated by us. If you click a
          third party link, you will be directed to that third party's site. We strongly advise you
          to review the Privacy Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content, privacy policies or
          practices of any third party sites or services.
        </p>
        <h3>16. Children's Privacy</h3>
        <p>Our Services are not intended for use by children under the age of 13 ("Children").</p>
        <p>
          We do not knowingly collect personally identifiable information from Children under 13. If
          you become aware that a Child has provided us with Personal Data, please contact us. If we
          become aware that we have collected Personal Data from Children without verification of
          parental consent, we take steps to remove that information from our servers.
        </p>
        <h3>17. Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy at any time by posting it on this site. It is your
          responsibility to review this Policy periodically.
        </p>
        <p>
          We may let you know via email and/or a prominent notice on our Service, about the change
          becoming effective and update "effective date" at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any changes. Changes to
          this Privacy Policy are effective when they are posted on this page.
        </p>
        <h3>18. Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <p>
          By email: <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>.
        </p>
        <p>
          By post: One9 Tech, 30, 2nd Floor, M. D. Bldg, Dr. M. C. Jawle Road, Dadar - West, Mumbai
          - 400028, India. Tel - +91-9870264671
        </p>
      </div>
      <Footer />
    </section>
  );
};

export default Privacy;
