import moment from 'moment';

const REFERENCE = moment(new Date());
const TODAY = REFERENCE.clone().startOf('day');
const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

export const secToMin = (sec) => Math.ceil(sec / 60);

export const formatDate = (uploadDate) =>
  new Date(uploadDate).toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const formatDateWithoutDay = (uploadDate) =>
  new Date(uploadDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export const isToday = (date) => moment(new Date(date)).isSame(TODAY, 'd');
export const isYesterday = (date) => moment(new Date(date)).isSame(YESTERDAY, 'd');
export const isEarlier = (date) => moment(new Date(date)).isBefore(YESTERDAY, 'd');

export const isWithinAWeek = (date) => moment(new Date(date)).isAfter(A_WEEK_OLD);
export const isTwoWeeksOrMore = (date) => !isWithinAWeek(moment(new Date(date)));

export const secondsToHms = (sec) => {
  sec = Number(sec);

  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);

  let hDisplay = h > 0 ? h + (h == 1 ? ' Hour' : ' Hours') : '';
  let mDisplay = m > 0 ? m + (m == 1 ? ' Minute' : ' Minutes') : '';
  const sDisplay = s > 0 ? s + (s == 1 ? ' Second' : ' Seconds') : '';

  return hDisplay + `${mDisplay ? ` ${mDisplay}` : ''}` + `${sDisplay ? ` ${sDisplay}` : ''}`;
};
