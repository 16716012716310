import ReactGA from 'react-ga4';
import { getCurrentuser } from './Authentication';
import { gaTrackingId } from './constants';

const init = (user) => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(gaTrackingId, { debug: isDev });
  if (user)
    ReactGA.set({
      userId: user.uid,
    });
};

const setGAUserId = (uid) =>
  ReactGA.set({
    userId: uid,
  });

const sendEvent = (payload) => {
  ReactGA.event(payload);
};

const sendPageview = (path) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

export default {
  init,
  sendEvent,
  sendPageview,
  setGAUserId,
};
