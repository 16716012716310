import { useCallback, useEffect, useState } from "react";

const useFilterType = (location) => {
    const { pathname, search } = location || {};
    const [filter, setFilter] = useState('');
    
    const findFilter = useCallback(() => {
        if (!pathname) return ''
        if (pathname.indexOf("upload") > -1) return 'upload'
        if (pathname.indexOf("dashboard") > -1) {
            if (!search) return ''
            if (search.indexOf('starred') > -1) {
                return 'starred'
            } else if (search.indexOf('archived') > -1) {
                return 'archived'
            }
        }
        return ''
    }, [pathname, search])

    useEffect(() => {
        setFilter(findFilter());
    }, [search, pathname]);

    return filter;
};

export default useFilterType;