import { Link } from 'react-router-dom';
import { ReactComponent as TranscriptIcon } from 'assets/icons/transcript.svg';
import './RecordingListItem.scss';
import { secToMin } from 'utils/dateTime.helper';
import { JOB_STATUS_MESSAGE } from 'utils/constants';

export default function RecordingListItem(props) {
  return (
    <Link to={`/transcript?oid=${props.recording._id}`} className="recording-list-item">
      <div className="details">
        <div className="title">{props.recording.name}</div>
        <div className="metadata">
          <span className="duration">{secToMin(props.recording.duration || '0')} min</span>
          <span className={`job-status ${(props.recording.jobStatus || "").toLowerCase()}`}>
            {JOB_STATUS_MESSAGE[props.recording.jobStatus] || props.recording.jobStatus}
          </span>
        </div>
      </div>

      <div className="calls-to-action">
        <span className="item">
          <TranscriptIcon className="transcript-icon icon" />
        </span>
      </div>
    </Link>
  );
}
