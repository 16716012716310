import Footer from 'components/footer/Footer';
import './Article.scss';

const Terms = () => {
  return (
    <section id="terms" className="article">
      <div className="container">
        <h1>Terms of Use</h1>
        <p className="date">Last update: 1st August, 2022</p>
        <h3>1. Introduction</h3>
        <p>
          These Terms of Service ("Terms", "Terms of Service") govern your use of our web pages
          located at{' '}
          <a href="https://transcrisp.com" target="_blank">
            Transcrisp
          </a>{' '}
          operated by{' '}
          <a href="https://one9.tech" target="_blank">
            One9 Tech.
          </a>{' '}
          ("us", "we", or "our")
        </p>
        <p>
          Our Privacy Policy also governs your use of our Service and explains how we collect,
          safeguard and disclose information that results from your use of our web pages. Please
          read it{' '}
          <a href="https://transcrisp.com/privacy" target="_blank">
            here
          </a>
          .
        </p>
        <p>
          Your agreement with us includes these Terms and our Privacy Policy ("Agreements"). You
          acknowledge that you have read and understood Agreements, and agree to be bound of them.
        </p>
        <p>
          If you do not agree with (or cannot comply with) Agreements, then you may not use the
          Service, but please let us know by emailing at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a> so we can try to find a
          solution. These Terms apply to all visitors, users and others who wish to access or use
          Service.
        </p>
        <p>Thank you for being responsible.</p>
        <h3>2. Communications</h3>
        <p>
          By creating an Account on our Service, you agree to subscribe to newsletters, marketing or
          promotional materials and other information we may send. However, you may opt out of
          receiving any, or all, of these communications from us by unchecking the items on your
          first visit or by emailing at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>.
        </p>
        <h3>3. Purchases</h3>
        <p>
          If you wish to purchase any product or service made available through Service
          ("Purchase"), you may be asked to supply certain information relevant to your Purchase
          including, without limitation, your credit card number, the expiration date of your credit
          card, your billing address, and your shipping information.
        </p>
        <p>
          You represent and warrant that: (i) you have the legal right to use any credit card(s) or
          other payment method(s) in connection with any Purchase; and that (ii) the information you
          supply to us is true, correct and complete.
        </p>
        <p>
          We may employ the use of third party services for the purpose of facilitating payment and
          the completion of Purchases. By submitting your information, you grant us the right to
          provide the information to these third parties subject to our Privacy Policy.
        </p>
        <p>
          We reserve the right to refuse or cancel your order at any time for reasons including but
          not limited to: product or service availability, errors in the description or price of the
          product or service, error in your order or other reasons.
        </p>
        <p>
          We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal
          transaction is suspected.
        </p>
        <h3>4. Contests, Sweepstakes and Promotions</h3>
        <p>
          Any contests, sweepstakes or other promotions (collectively, "Promotions") made available
          through Service may be governed by rules that are separate from these Terms of Service. If
          you participate in any Promotions, please review the applicable rules as well as our
          Privacy Policy. If the rules for a Promotion conflict with these Terms of Service,
          Promotion rules will apply.
        </p>
        <h3>5. Free Trial</h3>
        <p>
          Transcrisp may, at its sole discretion, offer a Transcription with a free trial for a
          limited period of time ("Free Trial").
        </p>
        <p>
          At any time and without notice, Transcrisp reserves the right to (i) modify Terms of
          Service of Free Trial offer, or (ii) cancel such Free Trial offer.
        </p>
        <h3>6. Fee Changes</h3>
        <p>
          Transcrisp, in its sole discretion and at any time, may modify Transcription fees for the
          Service.
        </p>
        <p>
          Transcrisp will provide you with a reasonable prior notice of any change in Transcription
          fees.
        </p>
        <p>
          Your continued use of Service after Transcription fee change comes into effect constitutes
          your agreement to pay the modified Transcription fee amount.
        </p>
        <h3>7. Refunds</h3>
        <p>
          The Service <b>does not offer refunds</b>. We provide free transcription minutes to new
          users so you can determine whether or not Transcrisp works for you. Kindly email us at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>. Our Service is powered by
          artificial intelligence and it does not work well for all situations.
        </p>
        <h3>8. Prohibited Uses</h3>
        <p>
          You may use Service only for lawful purposes and in accordance with Terms. You agree not
          to use Service:
        </p>
        <p>
          ( a ) In any way that violates any applicable national or international law or regulation.
        </p>
        <p>
          ( b ) For the purpose of exploiting, harming, or attempting to exploit or harm minors in
          any way by exposing them to inappropriate content or otherwise.
        </p>
        <p>
          ( c ) To transmit, or procure the sending of, any advertising or promotional material,
          including any "junk mail", "chain letter," "spam," or any other similar solicitation.
        </p>
        <p>
          ( d ) To impersonate or attempt to impersonate Transcrisp, a Transcrisp employee, another
          user, or any other person or entity.
        </p>
        <p>
          ( e ) In any way that infringes upon the rights of others, or in any way is illegal,
          threatening, fraudulent, or harmful, or in connection with any unlawful, illegal,
          fraudulent, or harmful purpose or activity.
        </p>
        <p>
          ( f ) To engage in any other conduct that restricts or inhibits anyone's use or enjoyment
          of Service, or which, as determined by us, may harm or offend Transcrisp or users of
          Service or expose them to liability.
        </p>

        <p>Additionally, you agree not to:</p>
        <p>
          ( a ) Use Service in any manner that could disable, overburden, damage, or impair Service
          or interfere with any other party's use of Service, including their ability to engage in
          real time activities through Service.
        </p>
        <p>
          ( b ) Use any robot, spider, or other automatic device, process, or means to access
          Service for any purpose, including monitoring or copying any of the material on Service.
        </p>
        <p>
          ( c ) Use any manual process to monitor or copy any of the material on Service or for any
          other unauthorized purpose without our prior written consent.
        </p>
        <p>
          ( d ) Use any device, software, or routine that interferes with the proper working of
          Service.
        </p>
        <p>
          ( e ) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is
          malicious or technologically harmful.
        </p>
        <p>
          ( f ) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts
          of Service, the server on which Service is stored, or any server, computer, or database
          connected to Service.
        </p>
        <p>
          ( g ) Attack Service via a denial-of-service attack or a distributed denial-of-service
          attack.
        </p>
        <p>( h ) Take any action that may damage or falsify Transcrisp's rating.</p>
        <p>( i ) Otherwise attempt to interfere with the proper working of Service.</p>
        <p>When using the API service for developers, you may not:</p>
        <p>
          ( a ) Build a product or service, which may compete with core products or transcription
          services offered by Transcrisp.
        </p>
        <p>
          ( b ) Build transcription websites, transcription services or applications that replicates
          or replaces the essential user experience of Transcrisp.
        </p>
        <p>
          ( c ) Build a transcription product using similar features, or functions of the
          Transcrisp.
        </p>

        <h3>9. Analytics</h3>
        <p>
          We may use third-party Service Providers to monitor and analyze the use of our Service.
        </p>
        <p>
          <b>Google Analytics</b>
        </p>
        <p>
          Google Analytics is a web analytics service offered by Google that tracks and reports
          website traffic. Google uses the data collected to track and monitor the use of our
          Service. This data is shared with other Google services. Google may use the collected data
          to contextualise and personalise the ads of its own advertising network.
        </p>
        <p>
          For more information on the privacy practices of Google, please visit the Google Privacy
          Terms web page:{' '}
          <a href="https://policies.google.com/privacy?hl=en" target="_blank">
            https://policies.google.com/privacy?hl=en
          </a>
        </p>
        <h3>10. No Use By Minors</h3>
        <p>
          Service is intended only for access and use by individuals at least eighteen (18) years
          old. By accessing or using any of Transcrisp's services, you warrant and represent that
          you are at least eighteen (18) years of age and with the full authority, right, and
          capacity to enter into this agreement and abide by all of the terms and conditions of
          Terms. If you are not at least eighteen (18) years old, you are prohibited from both the
          access and usage of Service.
        </p>
        <h3>11. Accounts</h3>
        <p>
          When you create an account with us, you guarantee that you are above the age of 18, and
          that the information you provide us is accurate, complete, and current at all times.
          Inaccurate, incomplete, or obsolete information may result in the immediate termination of
          your account on Service.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your account and password,
          including but not limited to the restriction of access to your computer and/or account.
          You agree to accept responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our Service or a third-party
          service. You must notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>
        <p>
          You may not use as a username the name of another person or entity or that is not lawfully
          available for use, a name or trademark that is subject to any rights of another person or
          entity other than you, without appropriate authorization. You may not use as a username
          any name that is offensive, vulgar or obscene.
        </p>
        <p>
          We reserve the right to refuse service, terminate accounts, remove or edit content, or
          cancel orders in our sole discretion.
        </p>
        <p>
          We reserve the right to terminate or remove data from accounts that are inactive for an
          extended period of time, as we reasonably determine, with or without notice.
        </p>
        <p>
          All the audio/video data submitted to Transcrisp is stored on our servers until you
          request to delete them.
        </p>
        <h3>12. Intellectual Property</h3>
        <p>
          Service and its original content (excluding Content provided by users), features and
          functionality are and will remain the exclusive property of Transcrisp and its licensors.
          Service is protected by copyright, trademark, and other laws of foreign countries. Our
          trademarks and trade dress may not be used in connection with any product or service
          without the prior written consent of Transcrisp.
        </p>
        <h3>13. Error Reporting and Feedback</h3>
        <p>
          You may provide us directly at{' '}
          <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a> with information and
          feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and
          other matters related to our Service ("Feedback"). You acknowledge and agree that: (i) you
          shall not retain, acquire or assert any intellectual property right or other right, title
          or interest in or to the Feedback; (ii) Transcrisp may have development ideas similar to
          the Feedback; (iii) Feedback does not contain confidential information or proprietary
          information from you or any third party; and (iv) Transcrisp is not under any obligation
          of confidentiality with respect to the Feedback. In the event the transfer of the
          ownership to the Feedback is not possible due to applicable mandatory laws, you grant
          Transcrisp and its affiliates an exclusive, transferable, irrevocable, free-of-charge,
          sub-licensable, unlimited and perpetual right to use (including copy, modify, create
          derivative works, publish, distribute and commercialize) Feedback in any manner and for
          any purpose.
        </p>
        <h3>14. Links to Other Sites</h3>
        <p>
          Our Service may contain links to third party web sites or services that are not owned or
          controlled by Transcrisp.
        </p>
        <p>
          Transcrisp has no control over, and assumes no responsibility for the content, privacy
          policies, or practices of any third party web sites or services. We do not warrant the
          offerings of any of these entities/individuals or their websites.
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT TRANSCRISP SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
          INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH
          USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
          THIRD PARTY WEB SITES OR SERVICES.
        </p>
        <p>
          WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD
          PARTY WEB SITES OR SERVICES THAT YOU VISIT.
        </p>
        <h3>15. Disclaimer Of Warranty </h3>
        <p>
          THESE SERVICES ARE PROVIDED BY TRANSCRISP ON AN "AS IS" AND "AS AVAILABLE" BASIS.
          TRANSCRISP MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
          THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
          THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY
          SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
        </p>
        <p>
          NEITHER TRANSCRISP NOR ANY PERSON ASSOCIATED WITH TRANSCRISP MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
          OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER TRANSCRISP NOR
          ANYONE ASSOCIATED WITH TRANSCRISP REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT,
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE,
          ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE
          SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
          SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR
          NEEDS OR EXPECTATIONS.
        </p>
        <p>
          TRANSCRISP HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
          NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
          APPLICABLE LAW.
        </p>
        <h3>16. Limitation Of Liability</h3>
        <p>
          EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND
          AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE,
          HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
          LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
          ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER
          TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT
          LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT
          AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
          REGULATIONS, EVEN IF TRANSCRISP HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF
          TRANSCRISP, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND
          UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT
          ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
          PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </p>
        <h3>17. Termination</h3>
        <p>
          We may terminate or suspend your account and bar access to Service immediately, without
          prior notice or liability, under our sole discretion, for any reason whatsoever and
          without limitation, including but not limited to a breach of Terms.
        </p>
        <p>If you wish to terminate your account, you may simply discontinue using Service.</p>
        <p>
          All provisions of Terms which by their nature should survive termination shall survive
          termination, including, without limitation, ownership provisions, warranty disclaimers,
          indemnity and limitations of liability.
        </p>
        <h3>18. Governing Law</h3>
        <p>
          These Terms shall be governed and construed in accordance with the laws of the country of
          India without regard to its conflict of law provisions.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not be considered a
          waiver of those rights. If any provision of these Terms is held to be invalid or
          unenforceable by a court, the remaining provisions of these Terms will remain in effect.
          These Terms constitute the entire agreement between us regarding our Service and supersede
          and replace any prior agreements we might have had between us regarding Service.
        </p>
        <h3>19. Changes To Service</h3>
        <p>
          We reserve the right to withdraw or amend our Service, and any service or material we
          provide via Service, in our sole discretion without notice. We will not be liable if for
          any reason all or any part of Service is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of Service, or the entire Service, to
          users, including registered users.
        </p>
        <h3>20. Amendments To Terms</h3>
        <p>
          We may amend Terms at any time by posting the amended terms on this site. It is your
          responsibility to review these Terms periodically.
        </p>
        <p>
          Your continued use of the Platform following the posting of revised Terms means that you
          accept and agree to the changes. You are expected to check this page frequently so you are
          aware of any changes, as they are binding on you.
        </p>
        <p>
          By continuing to access or use our Service after any revisions become effective, you agree
          to be bound by the revised terms. If you do not agree to the new terms, you are no longer
          authorized to use Service.
        </p>
        <h3>21. Waiver And Severability</h3>
        <p>
          No waiver by Transcrisp of any term or condition set forth in Terms shall be deemed a
          further or continuing waiver of such term or condition or a waiver of any other term or
          condition, and any failure of Transcrisp to assert a right or provision under Terms shall
          not constitute a waiver of such right or provision.
        </p>
        <p>
          If any provision of Terms is held by a court or other tribunal of competent jurisdiction
          to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated
          or limited to the minimum extent such that the remaining provisions of Terms will continue
          in full force and effect.
        </p>
        <h3>22. Acknowledgement</h3>
        <p>
          BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ
          THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
        </p>
        <h3>23. Contact Us</h3>
        <p>Please send your feedback, comments, requests for technical support:</p>
        <p>
          By email: <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>
        </p>
        <p>
          By post: One9 Tech, 30, 2nd Floor, M. D. Bldg, Dr. M. C. Jawle Road, Dadar - West, Mumbai
          - 400028, India. Tel - +91-9870264671
        </p>
      </div>
      <Footer />
    </section>
  );
};

export default Terms;
