import { useState, useEffect } from 'react';
import { Menu, MenuItem, MenuRadioGroup, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { logout } from 'utils/Authentication';
import { formatDateWithoutDay, secondsToHms } from 'utils/dateTime.helper';
import { getTimeRemaining } from 'utils/user.helper';
import { FEEDBACK_URL } from 'utils/constants';
import { useAppState } from 'appstate';

import { ReactComponent as AvatarPlaceholderIcon } from 'assets/icons/avatar-placeholder.svg';
import { ReactComponent as ThemeIcon } from 'assets/icons/theme.svg';
import { ReactComponent as FeedbackIcon } from 'assets/icons/feedback.svg';
import { ReactComponent as SignOutIcon } from 'assets/icons/sign-out.svg';

import './AccountMenu.scss';

export default function AccountMenu() {
  const [appTheme, setAppTheme] = useState('dark');
  const [state, dispatch] = useAppState();
  const {
    user: { user },
  } = state;

  useEffect(() => {
    const preferredTheme = localStorage.getItem('theme');
    if (preferredTheme) {
      document.documentElement.setAttribute('data-theme', preferredTheme);
      setAppTheme(preferredTheme);
    } else {
      const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
      setAppTheme(theme);
    }
  });

  const updateTheme = (e) => {
    const theme = e.value;
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
    setAppTheme(theme);
  };

  const timeRemaining = secondsToHms(getTimeRemaining(user));

  return (
    <Menu
      className="account-menu"
      align="end"
      offsetY={12}
      menuButton={
        <MenuButton className="account-menu-button">
          <span className="userame">{user.name || 'Acccount'}</span>
          <span className="avatar">
            {user.picture ? (
              <img className="avatar" src={user.picture} alt="profile picture" />
            ) : (
              <AvatarPlaceholderIcon />
            )}
          </span>
        </MenuButton>
      }
      transition
    >
      {timeRemaining && (
        <>
          <div className="account-credits">
            Free trial: {timeRemaining} remaining
            <br />
            Expiry: {formatDateWithoutDay(user.freeCreditExpiryDate)}
          </div>
        </>
      )}
      <MenuRadioGroup
        className="menu-theme-selector"
        value={appTheme}
        onRadioChange={(e) => setAppTheme(e.value)}
      >
        <div className="menu-item-wrapper">
          <ThemeIcon className="icon" />
          <span className="label">Theme:</span>
        </div>
        <div className="menu-theme-options">
          <MenuItem
            className={`menu-theme-item light ${appTheme === 'light' ? 'selected' : ''}`}
            type="radio"
            value="light"
            onClick={updateTheme}
          />
          <MenuItem
            className={`menu-theme-item dark ${appTheme === 'dark' ? 'selected' : ''}`}
            type="radio"
            value="dark"
            onClick={updateTheme}
          />
          {/* <MenuItem className={`menu-theme-item black ${appTheme === 'black' ? 'selected' : ''}`} type="radio" value="black" onClick={updateTheme} /> */}
        </div>
      </MenuRadioGroup>
      <MenuItem href={FEEDBACK_URL} target="_blank">
        <div className="menu-item-wrapper">
          <FeedbackIcon className="icon" />
          <span className="label">Leave feedback</span>
        </div>
      </MenuItem>
      <MenuDivider />
      <MenuItem
        className="menu-item-last"
        onClick={async () => {
          dispatch(logout());
          // Redirect user to login page
          window.location.href = '/';
        }}
      >
        <div className="menu-item-wrapper">
          <SignOutIcon className="icon" />
          <span className="label">Sign out</span>
        </div>
      </MenuItem>
    </Menu>
  );
}
