import { useEffect, useRef, useState } from 'react';
import autosize from 'autosize';

import './SpeechBlock.scss';

export default function SpeechBlock(props) {
  const [localSpeaker, setLocalSpeaker] = useState(props.speaker);
  const [localSpeech, setLocalSpeech] = useState(props.speech);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, []);

  const onSpeakerNameChange = (event) => {
    setLocalSpeaker(event.target.value);
  };

  const onSpeakerNameBlur = () => {
    if (!localSpeaker) {
      setLocalSpeaker(props.speaker);
    } else {
      props.onSpeakerNameBlur(localSpeaker);
    }
  };

  const onSpeechChange = (event) => {
    setLocalSpeech(event.target.value);
  };

  const onSpeechBlur = () => {
    if (!localSpeech) {
      setLocalSpeech(props.speech);
    } else if (localSpeech !== props.speech) {
      props.onSpeechBlur(localSpeech);
    }
  };

  return (
    <div className="speech-block">
      <div className="speech-details">
        <input
          className="speaker"
          onChange={onSpeakerNameChange}
          onBlur={onSpeakerNameBlur}
          value={localSpeaker}
        />
        {props.start_time && props.end_time ? (
          <div className="metadata">
            <span className="datetime" onClick={props.onTimeClick.bind(null, props.start_time)}>
              {props.start_time}
            </span>
            &nbsp;-&nbsp;
            <span className="datetime" onClick={props.onTimeClick.bind(null, props.end_time)}>
              {props.end_time}
            </span>
            &nbsp;seconds
          </div>
        ) : null}
      </div>
      <div className="speech-content">
        <textarea
          className="speech"
          ref={textareaRef}
          onChange={onSpeechChange}
          onBlur={onSpeechBlur}
          value={localSpeech}
        />
      </div>
    </div>
  );
}
