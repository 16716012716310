import { useAppState } from 'appstate';
import PaywallIllustration from 'assets/images/paywall.png';
import { useModal } from 'react-hooks-use-modal';
import { postTrialOrder, submitRecordingForTransctiption } from 'utils/api';
import { secondsToHms } from 'utils/dateTime.helper';
import { getTimeRemaining } from 'utils/user.helper';
import './transcription-status.scss';

export default function FreeTrial({ recording, onSuccess }) {
  const [state] = useAppState();
  const {
    user: { user },
  } = state;
  const { duration, _id } = recording;
  const timeRemaining = getTimeRemaining(user);

  const [ConfirmationModal, openConfirmationModal, closeConfirmationModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  });

  const handleOnClick = async () => {
    // await submitRecordingForTransctiption(_id);
    await postTrialOrder(_id);
    closeConfirmationModal();
    if (onSuccess) onSuccess();
  };

  return (
    <div className="transcription-status">
      <div className="illustration">
        <img src={PaywallIllustration} alt="paywall papa bear" />
      </div>
      <div className="transcription-details">
        <div className="transcription-title">Transcribe for free</div>
        <div className="transcription-description">
          You have <span>{secondsToHms(timeRemaining)}</span> available and will be consuming{' '}
          <span>{secondsToHms(duration)}</span>.
        </div>
        <button className="button primary" onClick={openConfirmationModal}>
          Transcribe for Free
        </button>
      </div>

      <ConfirmationModal>
        <div className="modal">
          <h1 className="modal-title"> Transcribe for free</h1>
          <p className="modal-body">
            This will consume <span>{secondsToHms(duration)}</span> from your free trial.
          </p>
          <div className="divider"></div>
          <div className="modal-actions">
            <button className="tertiary" onClick={closeConfirmationModal}>
              Cancel
            </button>
            <button className="primary" onClick={handleOnClick}>
              Confirm
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </div>
  );
}
