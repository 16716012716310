export const USER_ACTIONS = {
  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_ERROR: 'USER_ERROR',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_LOGOUT: 'USER_LOGOUT',
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case USER_ACTIONS.USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case USER_ACTIONS.USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.data,
      };
    }
    case USER_ACTIONS.USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case USER_ACTIONS.USER_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          ...action.data,
        },
      };
    }
    case USER_ACTIONS.USER_LOGOUT: {
      return {
        ...state,
        loading: false,
        error: null,
        user: {},
      };
    }
  }
};
