import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import './Stripe.scss';

import { URL } from 'utils/constants';
import { postStripeOrderFailure } from 'utils/api';
import { COUNTRIES } from 'utils/countries';

export const CheckoutForm = ({ clientSecret, oid }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState('');
  useEffect(() => {
    (async () => {
      if (!stripe || !clientSecret) {
        return;
      }
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      setPaymentIntentId(paymentIntent.id);
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        // TODO: Diaplays error just after posting order,
        // might be essential because this updates the status every time when there is update form stripe
        case 'requires_payment_method':
          // setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    })();
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${URL}/payments/stripe/order-success?oid=${oid}`,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    // TODO: Not tested
    if (error) await postStripeOrderFailure({ clientSecret, paymentIntent: paymentIntentId });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <h3 className="section-title">Payment Details</h3>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button className="button primary" disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </React.Fragment>
  );
};

export const StripeAddressForm = ({
  isDisabled,
  address,
  name,
  handleOnChange,
  clientSecret,
  handleStripeButtonClick,
}) => {
  return (
    <React.Fragment>
      <h3 className="section-title">Personal Details</h3>
      <div className="stripe-address-form">
        <div className="form-element-row" key={'name'}>
          <label className="form-element-label" for="name">
            Name
          </label>
          <div className="form-element-item">
            <input type="text" name="name" value={name} disabled={isDisabled} />
          </div>
        </div>
        <div className="form-element-row" key={'address-1'}>
          <label className="form-element-label" for="line1">
            Address Line 1
          </label>
          <div className="form-element-item">
            <input
              type="text"
              name="line1"
              disabled={isDisabled}
              value={address.line1}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="form-element-row" key={'address-2'}>
          <label className="form-element-label" for="line2">
            Address Line 2
          </label>
          <div className="form-element-item">
            <input
              type="text"
              name="line2"
              disabled={isDisabled}
              value={address.line2}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="form-element-row" key={'postal-code'}>
          <label className="form-element-label" for="postal_code">
            Postal Code
          </label>
          <div className="form-element-item">
            <input
              type="text"
              name="postal_code"
              disabled={isDisabled}
              value={address.postal_code}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="form-element-row" key={'city'}>
          <label className="form-element-label" for="city">
            City
          </label>
          <div className="form-element-item">
            <input
              type="text"
              name="city"
              disabled={isDisabled}
              value={address.city}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="form-element-row" key={'state'}>
          <label className="form-element-label" for="state">
            State
          </label>
          <div className="form-element-item">
            <input
              type="text"
              name="state"
              disabled={isDisabled}
              value={address.state}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="form-element-row" key={'country'}>
          <label className="form-element-label" for="country">
            Country
          </label>
          <div className="form-element-item">
            <select
              name="country"
              disabled={isDisabled}
              value={address.country}
              onChange={handleOnChange}
            >
              {Object.keys(COUNTRIES).map((countryCode) => (
                <option key={`stripe-${countryCode}`} value={countryCode}>
                  {COUNTRIES[countryCode]}
                </option>
              ))}
            </select>
          </div>
        </div>

        {clientSecret && isDisabled && (
          <div className="note">
            <b>Note: </b>To update your address please reach out at{' '}
            <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a>
          </div>
        )}
        {!clientSecret && (
          <>
            <div className="divider"></div>
            <div className="modal-actions">
              <button className="button primary" onClick={handleStripeButtonClick}>
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
