import { toast } from 'react-toastify';

const options = {
    position: toast.POSITION.BOTTOM_RIGHT,
    closeOnClick: true,
    pauseOnHover: true,
}

export const errorToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) msg = 'Someting went wrong.';
    toast.error(msg, options);
}

export const warningToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) return;
    toast.warn(msg, options);
}

export const infoToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) msg = 'Please reach out to us at hello@transcrisp.com';
    toast.info(msg, options);
}

export const successToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) msg = 'Updated Successfully';
    toast.success(msg, options);
}