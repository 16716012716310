import PaywallIllustration from 'assets/images/paywall.png';
import './transcription-status.scss';

export default function Failed() {
  return (
    <div className="transcription-status">
      <div className="illustration">
        <img src={PaywallIllustration} alt="paywall papa bear" />
      </div>
      <div className="transcription-details">
        <div className="transcription-title">Transcription Failed</div>
        <div className="transcription-description">
          Please reach out to us to resolve your issue
        </div>
        <a role="button" className="button primary transcription-contact-button" href="mailto:hello@transcrisp.com">
          Contact Us
        </a>
      </div>
    </div>
  );
}
