import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useModal } from 'react-hooks-use-modal';

import PaywallIllustration from 'assets/images/paywall.png';
import { secToMin } from 'utils/dateTime.helper';
import { postRazorpayOrder, postRazorpayOrderFailure, postStripeOrder } from 'utils/api';
import {
  razorpayKeyId,
  STRIPE_API_KEY,
  URL,
  DEFAULT_BILLING_ADDRESS,
  PAYMENT_OPTIONS,
  DEFAULT_COUNTRY_CODE,
} from 'utils/constants';
import logo from 'assets/logo-light.svg';
import { getCurrentuser } from 'utils/Authentication';
import analytics from 'utils/Analytics';
import Loader from 'components/loader/Loader';
import { errorToast } from 'utils/toast';
import { StripeAddressForm, CheckoutForm } from './Stripe';
import { useAppState } from 'appstate';

import './Paywall.scss';
import { Elements } from '@stripe/react-stripe-js';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(STRIPE_API_KEY);

export default function Paywall(props) {
  const [PaymentModal, openPaymentModal, closePaymentModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  });
  const [StripeModal, openStripeModal, closeStripeModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  });
  const [state] = useAppState();
  const [payButtonDisable, setPayButtonDisable] = useState(false);
  const [clientSecretForStripe, setClientSecretForStripe] = useState('');
  const {
    user: { user },
  } = state || {};

  const { paymentDetails } = props || {};
  const { billingAddress, stripeCustomerId, name } = user || {};
  const [isDisabled, setIsDisabled] = useState(!!stripeCustomerId);

  const paymentOption = PAYMENT_OPTIONS[user.countryCode || DEFAULT_COUNTRY_CODE];
  const {
    amount = '',
    currency = paymentOption.CURRENCY,
    priceDuration = paymentOption.PRICE_DURATION,
    pricePerDuration = paymentOption.PRICE_PER_DURATION,
    actualPrice = paymentOption.ACTUAL_PRICE,
  } = paymentDetails || {};

  const [address, setAddress] = useState(billingAddress || DEFAULT_BILLING_ADDRESS);

  const appearance = { theme: localStorage.getItem('theme') === 'light' ? 'stripe' : 'night' };
  const options = { clientSecret: clientSecretForStripe, appearance };

  /***************** Stripe Payment Logic Starts *****************/

  const handleStripeOpenModal = async () => {
    openStripeModal();
    closePaymentModal();
    if (stripeCustomerId) {
      setPayButtonDisable(true);
      const data = await postStripeOrder(props.oid, stripeCustomerId, address);
      setClientSecretForStripe(data.clientSecret);
      setPayButtonDisable(false);
    }
  };
  const handleStripeButtonClick = async () => {
    const data = await postStripeOrder(props.oid, stripeCustomerId, address);
    setClientSecretForStripe(data.clientSecret);
    setIsDisabled(true);
  };

  const handleStripeAddressChange = (e) => {
    if (stripeCustomerId) return;
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  /***************** Stripe Payment Logic Ends *****************/

  /***************** Razorpay Payment Logic Starts *****************/

  const handleRazorpayButtonClick = async () => {
    setPayButtonDisable(true);
    const { orderId } = await postRazorpayOrder(props.oid);
    closePaymentModal();
    const user = getCurrentuser();
    const options = {
      key: razorpayKeyId,
      name: 'Transcrisp',
      description: 'Payment for transcription on the Transcrisp app.',
      image: logo,
      order_id: orderId,
      callback_url: `${URL}/payments/razorpay/order-success`,
      // handler: handleRazorpaySuccess, // This handler makes similar api calls like the razorpay callback_url which can be used in during local development.
      prefill: {
        name: user.displayName,
        email: user.email,
      },
    };
    analytics.sendEvent({ category: 'Button', action: 'PayButton' });
    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', handleRazorpayFailure);
    paymentObject.open();
    setPayButtonDisable(false);
  };

  // Used for local development
  // const handleRazorpaySuccess = async (res) => {
  //   await postRazorpayOrderSuccess({
  //     razorpay_payment_id: res.razorpay_payment_id,
  //     razorpay_order_id: res.razorpay_order_id,
  //     razorpay_signature: res.razorpay_signature,
  //   });
  //   analytics.sendEvent({ category: 'Payment', action: 'Success' });

  //   // UI-TODO: Write Reloading logic
  //   window.location.reload(false);
  // };

  const handleRazorpayFailure = async (res) => {
    errorToast();
    console.error(res.error);
    await postRazorpayOrderFailure(res.error);
    analytics.sendEvent({ category: 'Payment', action: 'Failure' });
  };

  /***************** Stripe Razorpay Logic Ends *****************/

  /***************** Rendering Modal and components Logic Starts *****************/

  const renderStripeModal = () => {
    return (
      <StripeModal>
        <div className="modal stripe-checkout-modal">
          <h1 className="modal-title">Payment Checkout</h1>
          <p className="modal-body">
            {payButtonDisable ? (
              <Loader inline />
            ) : (
              <>
                <StripeAddressForm
                  isDisabled={isDisabled}
                  name={name}
                  address={address}
                  handleOnChange={handleStripeAddressChange}
                  handleStripeButtonClick={handleStripeButtonClick}
                  clientSecret={clientSecretForStripe}
                />
                {clientSecretForStripe && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm clientSecret={clientSecretForStripe} oid={props.oid} />
                  </Elements>
                )}
              </>
            )}
          </p>
        </div>
      </StripeModal>
    );
  };

  const renderPaymentOptionsModal = () => {
    return (
      <PaymentModal>
        <div className="modal payment-selection-modal">
          <h1 className="modal-title">Payment Options</h1>
          <p className="modal-body">
            <button
              className="button tertiary"
              disabled={payButtonDisable}
              onClick={handleStripeOpenModal}
            >
              Pay in USD <span className="helper-text">(Stripe)</span>
            </button>
            <button
              className="button tertiary"
              onClick={handleRazorpayButtonClick}
              disabled={payButtonDisable}
            >
              Pay in INR <span className="helper-text">(Razorpay)</span>
            </button>
          </p>
        </div>
      </PaymentModal>
    );
  };

  const renderPayNowButton = () => {
    return (
      <button
        id="pay-button"
        className="button primary"
        onClick={openPaymentModal}
        disabled={payButtonDisable}
      >
        Pay Now
      </button>
    );
  };

  const renderButtonAndModal = () => {
    return (
      <>
        {renderPayNowButton()}
        {renderPaymentOptionsModal()}
        {renderStripeModal()}
      </>
    );
  };

  /***************** Rendering Modal and components Logic Ends *****************/

  return (
    <div className="paywall">
      <div>
        <img
          className="illustration monochromatic accent"
          src={PaywallIllustration}
          alt="paywall papa bear"
        />
      </div>
      <div className="paywall-details">
        <div className="introductory-price">
          <div className="offer-price-wrapper">
            <span className="original">
              {currency} {actualPrice}
            </span>
            <div className="offer">
              <span className="value">
                {currency} {pricePerDuration}
              </span>
              <span className="metric">/{priceDuration} minutes</span>
            </div>
            <span className="offer-tag">introductory price</span>
          </div>
          {/* <img src={IntrodutoryPrice} alt={`introductory price - ${currency} ${pricePerDuration} per ${priceDuration} minutes`} /> */}
        </div>
        <div className="paywall-metadata">
          <div className="paywall-title">Unlock to Transcribe</div>
          <div className="metadata-item">
            <span className="label">Recording Length:</span>
            <span className="value">{secToMin(props.duration)} minutes</span>
          </div>
          <div className="metadata-item">
            <span className="label">Transcription Cost:</span>
            <span className="value">{`${currency} ${amount} (incl. taxes)`}</span>
          </div>
        </div>
        {payButtonDisable ? <Loader fullScreen /> : renderButtonAndModal()}
      </div>
    </div>
  );
}
