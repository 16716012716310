export const SUPPORTED_TRANSCRIPTION_LANGUAGES = {
  Afrikaans: 'af-ZA',
  'Arabic, Gulf': 'ar-AE',
  'Arabic, Modern Standard': 'ar-SA',
  'Chinese, Simplified': 'zh-CN',
  'Chinese, Traditional': 'zh-TW',
  Danish: 'da-DK',
  Dutch: 'nl-NL',
  'English, Australian': 'en-AU',
  'English, British': 'en-GB',
  'English, Indian': 'en-IN',
  'English, Irish': 'en-IE',
  'English, New Zealand': 'en-NZ',
  'English, Scottish': 'en-AB',
  'English, South African': 'en-ZA',
  'English, US': 'en-US',
  'English, Welsh': 'en-WL',
  French: 'fr-FR',
  'French, Canadian': 'fr-CA',
  Farsi: 'fa-IR',
  German: 'de-DE',
  'German, Swiss': 'de-CH',
  Hebrew: 'he-IL',
  'Hindi, Indian': 'hi-IN',
  Indonesian: 'id-ID',
  Italian: 'it-IT',
  Japanese: 'ja-JP',
  Korean: 'ko-KR',
  Malay: 'ms-MY',
  Portuguese: 'pt-PT',
  'Portuguese, Brazilian': 'pt-BR',
  Russian: 'ru-RU',
  Spanish: 'es-ES',
  'Spanish, US': 'es-US',
  Tamil: 'ta-IN',
  Telugu: 'te-IN',
  Thai: 'th-TH',
  Turkish: 'tr-TR',
};

export const getKey = (obj, value) => Object.keys(obj).find((key) => obj[key] === value);

export const getKeyBasedOnLangCode = (obj, value) =>
  obj[Object.keys(obj).find((key) => value.indexOf(obj[key]) > -1)];

export const SUPPORTED_TRANSLATION_LANGUAGES = {
  Afrikaans: 'af',
  Albanian: 'sq',
  Amharic: 'am',
  Arabic: 'ar',
  Armenian: 'hy',
  Azerbaijani: 'az',
  Bengali: 'bn',
  Bosnian: 'bs',
  Bulgarian: 'bg',
  Catalan: 'ca',
  'Chinese (Simplified)': 'zh',
  'Chinese (Traditional)': 'zh-TW',
  Croatian: 'hr',
  Czech: 'cs',
  Danish: 'da',
  Dari: 'fa-AF',
  Dutch: 'nl',
  English: 'en',
  Estonian: 'et',
  'Farsi (Persian)': 'fa',
  'Filipino, Tagalog': 'tl',
  Finnish: 'fi',
  French: 'fr',
  'French (Canada)': 'fr-CA',
  Georgian: 'ka',
  German: 'de',
  Greek: 'el',
  Gujarati: 'gu',
  'Haitian Creole': 'ht',
  Hausa: 'ha',
  Hebrew: 'he',
  Hindi: 'hi',
  Hungarian: 'hu',
  Icelandic: 'is',
  Indonesian: 'id',
  Irish: 'ga',
  Italian: 'it',
  Japanese: 'ja',
  Kannada: 'kn',
  Kazakh: 'kk',
  Korean: 'ko',
  Latvian: 'lv',
  Lithuanian: 'lt',
  Macedonian: 'mk',
  Malay: 'ms',
  Malayalam: 'ml',
  Maltese: 'mt',
  Marathi: 'mr',
  Mongolian: 'mn',
  Norwegian: 'no',
  Pashto: 'ps',
  Polish: 'pl',
  'Portuguese (Brazil)': 'pt',
  'Portuguese (Portugal)': 'pt-PT',
  Punjabi: 'pa',
  Romanian: 'ro',
  Russian: 'ru',
  Serbian: 'sr',
  Sinhala: 'si',
  Slovak: 'sk',
  Slovenian: 'sl',
  Somali: 'so',
  Spanish: 'es',
  'Spanish (Mexico)': 'es-MX',
  Swahili: 'sw',
  Swedish: 'sv',
  Tamil: 'ta',
  Telugu: 'te',
  Thai: 'th',
  Turkish: 'tr',
  Ukrainian: 'uk',
  Urdu: 'ur',
  Uzbek: 'uz',
  Vietnamese: 'vi',
  Welsh: 'cy',
};
