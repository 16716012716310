export const getTimeRemaining = (user) => {
  if (!user) return null;
  const { consumedTime, allowedTime } = user;
  return allowedTime - consumedTime > 0 ? allowedTime - consumedTime : 0;
};

export const isTimeRemainingAvailable = (user, recording) => {
  if (!user || !recording) return null;
  const timeRemaining = getTimeRemaining(user);
  const { duration } = recording;
  return timeRemaining > duration;
};
