import axios from 'axios';
import { getAudioFileDuration } from './file.helper';
import { getAuthToken } from './Authentication';
import { URL } from './constants';
import { errorToast } from './toast';

const getCommonHeaders = async () => ({
  headers: {
    token: await getAuthToken(),
  },
});

export const getRecordings = async () => {
  try {
    const response = await axios.get(URL + '/dashboard/get-data', await getCommonHeaders());
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const getRecordingData = async (oid) => {
  try {
    const response = await axios.get(
      URL + `/dashboard/get-recording?oid=${oid}`,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const uploadRecording = async (file, title, noOfSpeakers, language, onUploadProgress) => {
  try {
    const formData = new FormData();
    formData.append('recording', file);
    formData.append('recordingDuration', await getAudioFileDuration(file));
    if (title) formData.append('title', title);
    if (noOfSpeakers) formData.append('noOfSpeakers', noOfSpeakers);
    formData.append('language', language);

    const response = await axios.post(URL + '/dashboard/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data', ...(await getCommonHeaders()).headers },
      onUploadProgress,
    });
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

// For downloading the transcription results
export const getTranscriptionResults = async (oid, language = false) => {
  try {
    let url = `${URL}/transcription/result?oid=${oid}`;
    if (language) url += `&language=${language}`;
    let response = await axios.get(url, await getCommonHeaders());
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

// updating the status of the transctiption job
export const checkTranscriptionStatus = async (oid) => {
  try {
    let url = `${URL}/transcription/check-status?oid=${oid}`;
    let response = await axios.get(url, await getCommonHeaders());
    return response.data.TranscriptionJobStatus;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const updateRecordingName = async (oid, newRecordingName) => {
  try {
    const body = { oid, updateData: { name: newRecordingName } };
    let response = await axios.post(
      URL + '/dashboard/update-recording-data',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const updateStarred = async (oid, starred = true) => {
  try {
    const body = { oid, updateData: { starred } };
    let response = await axios.post(
      URL + '/dashboard/update-recording-data',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const updateArchived = async (oid, archived = true) => {
  try {
    const body = { oid, updateData: { archived } };
    let response = await axios.post(
      URL + '/dashboard/update-recording-data',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const deleteDashboardData = async (oid) => {
  try {
    const body = { oid };
    let response = await axios.post(
      URL + '/dashboard/delete-recording',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const submitRecordingForTransctiption = async (oid) => {
  try {
    const body = { oid };
    let response = await axios.post(
      URL + '/transcription/submit-recording',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const updateTranscription = async (oid, language, transcript) => {
  try {
    const body = { oid, language, transcript };
    let response = await axios.post(
      URL + '/transcription/update-transcription',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const downloadRecording = async (oid) => {
  try {
    const response = await axios.get(`${URL}/dashboard/download?oid=${oid}`, {
      responseType: 'arraybuffer',
      ...(await getCommonHeaders()),
      onDownloadProgress: console.log,
    });

    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const getRecordingUrl = async (oid, language, extension) => {
  try {
    const response = await axios.get(
      `${URL}/dashboard/get-recording-url?oid=${oid}&language=${language}&extension=${extension}`,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const postTrialOrder = async (oid) => {
  try {
    const body = { oid };
    let response = await axios.post(
      URL + '/payments/trial/order-success',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const postRazorpayOrder = async (oid) => {
  try {
    const body = { oid };
    let response = await axios.post(
      URL + '/payments/razorpay/order',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const postRazorpayOrderSuccess = async (orderSuccessDetails) => {
  try {
    const body = { ...orderSuccessDetails };
    let response = await axios.post(
      URL + '/payments/razorpay/order-success',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const postRazorpayOrderFailure = async (failureDetails) => {
  try {
    const body = { ...failureDetails };
    let response = await axios.post(
      URL + '/payments/razorpay/order-failure',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const udpateUserCookieConsent = async (cookieConsent, cookieConsentPreference) => {
  try {
    const body = { cookieConsent, cookieConsentPreference };
    let response = await axios.post(URL + '/user/', body, await getCommonHeaders());
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const getUserData = async () => {
  try {
    const response = await axios.get(URL + '/user/', await getCommonHeaders());
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

const apiErrorHandler = (err) => {
  const { response } = err || {};
  const { data } = response || {};

  if (response) console.error(err.response);
  if (data) return errorToast(data.error);

  errorToast('Someting went wrong.');

  // Temporarily not throwing errors uncomment when handling errors form UI
  // throw err;
};

export const postStripeOrder = async (oid, stripeCustomerId, address) => {
  try {
    const body = { oid, address, stripeCustomerId };
    let response = await axios.post(URL + '/payments/stripe/order', body, await getCommonHeaders());
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const postStripeOrderFailure = async (data) => {
  try {
    const body = { ...data };
    let response = await axios.post(
      URL + '/payments/stripe/order-failure',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

export const translateContent = async (oid, destinationLanguage) => {
  try {
    const body = { oid, destinationLanguage };
    let response = await axios.post(
      URL + '/transcription/translate',
      body,
      await getCommonHeaders()
    );
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};
