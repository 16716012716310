import { useAppState } from 'appstate';
import { getRedirectURL, loadCannySDK } from 'components/analytics/Canny';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSearchParams } from 'react-router-dom';
import { USER_ACTIONS } from 'reducers/user';
import { getUserData } from 'utils/api';
import { auth, initializeGoogleOneTap } from 'utils/Authentication';
import { RAZOR_PAY_SCRIPT_SRC } from 'utils/constants';
import { loadScript } from 'utils/externalScriptloader.helper';

export const useUserDetails = () => {
  const [firebaseUser, loading] = useAuthState(auth);
  const [state, dispatch] = useAppState();
  const { user } = state;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!firebaseUser && !loading) {
      initializeGoogleOneTap();
    }
  }, [firebaseUser, loading]);

  useEffect(() => {
    const loadCanny = async (response) => {
      // If the user is coming from canny to our login page it will have a redirect url
      if (searchParams && searchParams.get('redirect') && response.cannyToken) {
        await loadCannySDK(response);
        const redirectURL = getRedirectURL(response.cannyToken);
        if (redirectURL) {
          window.location.assign(redirectURL);
        }
      } else {
        loadCannySDK(response);
      }
    };

    const loadRazorpay = async () => {
      await loadScript(RAZOR_PAY_SCRIPT_SRC);
    };

    const getUsers = async () => {
      if (!loading && !user.loading && !user.user) {
        dispatch({ type: USER_ACTIONS.USER_REQUEST });
        const response = await getUserData();
        if (response) {
          dispatch({ type: USER_ACTIONS.USER_SUCCESS, data: response });
          loadCanny(response);
          loadRazorpay();
        }
      }
    };
    getUsers();
  }, [firebaseUser]);

  // return [customUser, isAuthenticated];
  return firebaseUser;
};
