import { Link, useNavigate } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as AudioFileIcon } from 'assets/icons/audio-file.svg';
import { uploadRecording } from 'utils/api';
import { delay } from 'utils/delay.helper';
import './UploadRecording.scss';
import { errorToast, warningToast } from 'utils/toast';
import { fileSize, getAudioFileDuration } from 'utils/file.helper';
import { secondsToHms } from 'utils/dateTime.helper';
import { getTimeRemaining, isTimeRemainingAvailable } from 'utils/user.helper';
import { useAppState } from 'appstate';
import {
  DEFAULT_TRANSCRIPTION_LANGUAGE,
  MAX_FILE_UPLOAD_SIZE,
  MAX_RECORDING_UPLOAD_DURATION,
  MAX_SPEAKERS_ALLOWED,
} from 'utils/constants';
import { getKey, SUPPORTED_TRANSCRIPTION_LANGUAGES } from 'utils/languages';

export default function UploadRecording() {
  const [selectedFile, setSelectedFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [title, setTitle] = useState('');
  const [noOfSpeakers, setNoOfSpeakers] = useState('');
  const [language, setLanguage] = useState(DEFAULT_TRANSCRIPTION_LANGUAGE);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [error, setError] = useState('');
  const [duration, setDuration] = useState();

  const [state] = useAppState();
  const {
    user: { user },
  } = state;
  const navigate = useNavigate();

  const [ConfirmModal, openConfirmModal, closeConfirmModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  });

  const fileChangeHandler = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_UPLOAD_SIZE) {
      errorToast('File Size exceeds 100MB, pelase Select file which is less than 100MB in size.');
      // TO reset the selected file
      event.target.value = '';
      return;
    }

    const duration = await getAudioFileDuration(file);
    const timeRemaining = secondsToHms(getTimeRemaining(user));

    if (duration > MAX_RECORDING_UPLOAD_DURATION) {
      errorToast(
        'Recording length exceeds 60 Minutes, pelase select a recording which is less than 60 Minutes.'
      );
      // TO reset the selected file
      event.target.value = '';
      return;
    }

    if (!isTimeRemainingAvailable(user, { duration }))
      warningToast(
        `You have exhausted your available minutes. You will be asked to pay on next page. You have ${timeRemaining} free remaining.`
      );

    setDuration(duration);
    setError('');
    setSelectedFile(file);
    setIsFilePicked(true);
    if (!title) setTitle(file.name);
  };

  const uploadProgressHandler = (event) => {
    const percentage = Math.round((100 * event.loaded) / event.total);
    // Not setting the progress for 100 as sometimes when the file is big
    // The server takes to much time and uploadProgressHandler just captures the file sent from client
    // When the file is getting uploaded from server to s3 that is not tracked.
    // So setting 100 when the upload api success has come
    if (percentage !== 100) setUploadPercentage(percentage);
  };

  const startUploading = async () => {
    try {
      if (!isFilePicked) {
        setError('Please select a file');
        return;
      } else if (!title) {
        setError('Please enter a Title');
        return;
      } else if (!noOfSpeakers) {
        setError('Please enter number of speakers for better transcription.');
        return;
      } else if (/\D/.test(noOfSpeakers) || noOfSpeakers > 10) {
        setError('Number of speakers must be a number and less than 10');
        return;
      }
      if (isUploading) return;

      setIsUploading(true);
      
      closeConfirmModal();

      const { insertedId, warning } = await uploadRecording(
        selectedFile,
        title,
        noOfSpeakers,
        language,
        uploadProgressHandler
      );

      if (warning) warningToast(warning);

      if (insertedId) {
        setUploadPercentage(100);
        await delay(500);
        return navigate(`/transcript?oid=${insertedId}`);
      }

      setIsUploading(false);
      navigate('/');
    } catch (err) {
      // errorToast();
      setIsUploading(false);
    }
  };

  const formPreventDefaultHandler = (event) => {
    event.preventDefault();
    return false;
  };

  const cancelButtonHandler = () => {
    setSelectedFile(null);
    setIsUploading(false);
    setIsFilePicked(false);
    setTitle('');
    setNoOfSpeakers('');
    setUploadPercentage(0);
  };

  const handleUploadClick = () => {
    if (language === DEFAULT_TRANSCRIPTION_LANGUAGE) {
      startUploading();
    } else {
      openConfirmModal();
    }
  };

  const renderConfirmationModal = () => {
    const selectedLanguage = getKey(SUPPORTED_TRANSCRIPTION_LANGUAGES, language);
    return (
      <ConfirmModal>
        <div className="modal">
          <h1 className="modal-title">Language Changed?</h1>
          <p className="modal-body">
            You've changed the language to <b>{selectedLanguage}</b>. Please confirm if you're audio
            is in the <b>{selectedLanguage}</b> language
          </p>
          <div className="divider"></div>
          <div className="modal-actions">
            <button className="tertiary" onClick={closeConfirmModal}>
              Recheck
            </button>
            <button className="primary" onClick={startUploading}>
              Confirm
            </button>
          </div>
        </div>
      </ConfirmModal>
    );
  };

  const onDrop = useCallback((files) => {
    fileChangeHandler({
      target: {
        files,
      },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'audio/mp3': ['.mp3'],
    },
    multiple: false,
    disabled: isUploading
  });

  return (
    <main className="upload-recording">
      <form id="upload-metadata" onSubmit={formPreventDefaultHandler}>
        <section className="controls">
          <div className="title">New Recording</div>
          {!isUploading && isFilePicked && (
            <Link to="/upload" onClick={cancelButtonHandler} className="cancel-button">
              <span className="label">Cancel Upload</span>
              <CrossIcon className="cross-icon icon" />
            </Link>
          )}
        </section>

        <section className="upload-box">
          <div {...getRootProps({ className: 'dropzone dropzone-container' })}>
            <AudioFileIcon className="audio-file-icon icon" />
            <div className="description">
              Drop your audio file here
              <br />
              or
              <label>
                <div>
                  <input
                    // type="file"
                    // accept="audio/mp3" // Currently supporting only mp3 - Add this when all other formats are supported - "audio/*"
                    // style={{ display: 'none' }}
                    // onChange={fileChangeHandler}
                    form="upload-metadata"
                    {...getInputProps()}
                  />
                </div>
                <a>browse for a file on your device</a>
              </label>
            </div>
            <span className="formats">
              Supported formats: MP3
              <br />
              Formats coming soon: MP4, Ogg, WebM, or WAV
            </span>
          </div>
          {isFilePicked ? (
            <div className="progress-wrapper">
              <div className="progress-bar">
                <div className="total"></div>
                <div className="completed" style={{ width: `${uploadPercentage}%` }}></div>
              </div>
              <div className="progress-metadata">
                <div className="file-details">
                  <span className="file-name">{selectedFile.name}</span>
                  <span className="file-size">File Size: {fileSize(selectedFile)}</span>
                  <span className="file-duration">Duration: {secondsToHms(duration)}</span>
                </div>
                {uploadPercentage ? (
                  <div className="percentage">Uploading - {uploadPercentage}%</div>
                ) : null}
              </div>
            </div>
          ) : (
            ' '
          )}

          <div className="upload-metadata">
            <div className="title">
              <label htmlFor="title">Title:</label>
              <input
                form="upload-metadata"
                type="text"
                name="title"
                placeholder="Enter a title for this recording"
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                  setError('');
                }}
                disabled={isUploading}
              />
            </div>
            <div className="speakers">
              <label htmlFor="speakers">No. of speakers:</label>
              <input
                form="upload-metadata"
                type="number"
                name="speakers"
                min="1"
                max={MAX_SPEAKERS_ALLOWED}
                value={noOfSpeakers}
                onChange={(event) => {
                  setNoOfSpeakers(event.target.value);
                  setError('');
                }}
                disabled={isUploading}
              />
            </div>
            <div className="language">
              <label htmlFor="language">Audio language:</label>
              <select
                form="upload-metadata"
                name="language"
                value={language}
                onChange={(event) => {
                  setLanguage(event.target.value);
                  setError('');
                }}
                disabled={isUploading}
              >
                {Object.keys(SUPPORTED_TRANSCRIPTION_LANGUAGES).map((language) => (
                  <option
                    key={`language-${language}`}
                    value={SUPPORTED_TRANSCRIPTION_LANGUAGES[language]}
                  >
                    {language}
                  </option>
                ))}
              </select>
            </div>
            <div className="audio-lang-note">
              Note: We use machine language for transcribing and it may not be completely accurate.
              You can edit the transcription once it's transcribed.
            </div>
          </div>
        </section>
        <div className="validation-error" hidden={!error}>
          *{error}
        </div>
        <section className="call-to-action">
          <div>
            <button
              type="button"
              form="upload-metadata"
              className="upload-button primary"
              onClick={handleUploadClick}
              disabled={isUploading}
            >
              Start Uploading
            </button>
          </div>
        </section>
      </form>
      {renderConfirmationModal()}
    </main>
  );
}
