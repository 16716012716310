import { cannyAppId, CANNY_SCRIPT_SRC } from 'utils/constants';
import { loadScript } from 'utils/externalScriptloader.helper';
import { errorToast } from 'utils/toast';

export const loadCannySDK = async (user) => {
  try {
    await loadScript(CANNY_SCRIPT_SRC);

    if (!window.Canny) return console.error('[Canny] Script Not loaded');
    if (!user) return console.error('[Canny] User Not Found');

    window.Canny('identify', {
      appID: cannyAppId,
      user: {
        // Replace these values with the current user's data
        email: user.email,
        name: user.name,
        id: user._id,
        customFields: {
          // Any custom fields should go here
        },
        // These fields are optional, but recommended:
        avatarURL: user.picture || '',
      },
    });
  } catch (err) {
    errorToast();
    console.error(err);
  }
};

const getQueryParameterByName = (name) => {
  const pairStrings = window.location.search.slice(1).split('&');
  const pairs = pairStrings.map(function (pair) {
    return pair.split('=');
  });
  return pairs.reduce(function (value, pair) {
    if (value) return value;
    return pair[0] === name ? decodeURIComponent(pair[1]) : null;
  }, null);
};

export const getRedirectURL = (ssoToken) => {
  const redirectURL = getQueryParameterByName('redirect');
  const companyID = getQueryParameterByName('companyID');
  if (redirectURL.indexOf('https://') !== 0 || !companyID) {
    return null;
  }

  return `https://canny.io/api/redirects/sso?companyID=${companyID}&ssoToken=${ssoToken}&redirect=${redirectURL}`;
};
