import './NotFound.scss';
import NotFoundImage from 'assets/images/404.svg';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="illustration">
        <img src={NotFoundImage} alt="not found image" />
      </div>
      <div className="not-found-details">
        <div className="not-found-title">Page Not Found!</div>
        <div className="not-found-description">
          Looks like you're lost, please click <Link to="/">here</Link> to go to home page
        </div>
      </div>
    </div>
  );
};

export default NotFound;
