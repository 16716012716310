import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { setCookie } from 'react-use-cookie';
import { USER_ACTIONS } from 'reducers/user';

import {
  CONSENT_COOKIE_NAME,
  CONSENT_PREFERENCE_COOKIE_NAME,
  firebaseConfig,
  googleOneTabClientId,
  GOOGLE_ONE_TAP_SCRIPT_SRC,
} from './constants';
import { loadScript } from './externalScriptloader.helper';
import { errorToast } from './toast';

const app = firebase.initializeApp(firebaseConfig);
const googleProvider = new firebase.auth.GoogleAuthProvider();

export const initializeGoogleOneTap = async () => {
  try {
    await loadScript(GOOGLE_ONE_TAP_SCRIPT_SRC);

    if (!window.google) return console.error('[GSI Client] Script Not loaded');
    if (!googleOneTabClientId) return console.error('[GSI Client] Invalid or no client id');

    window.google.accounts.id.initialize({
      client_id: googleOneTabClientId,
      callback: signInWithCredential,
      auto_select: false,
      cancel_on_tap_outside: true,
      context: 'use',
    });

    window.setTimeout(googleOneTap, 500);
  } catch (err) {
    errorToast();
    console.error(err);
  }
};

export const auth = app.auth();

export const signInWithGooglePopup = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    // Uncomment while debuging
    // const user = res.user;
    // console.log(user);
  } catch (err) {
    console.error(err);
    errorToast(err.message);
  }
};

export const signInWithGoogleRedirect = async () => {
  try {
    const res = await auth.signInWithRedirect(googleProvider);
    // Uncomment while debuging
    // const user = res.user;
    // console.log(user);
  } catch (err) {
    console.error(err);
    errorToast(err.message);
  }
};

// For Google One Tap
export const signInWithCredential = (result) => {
  try {
    const credential = firebase.auth.GoogleAuthProvider.credential(result.credential);
    auth.signInWithCredential(credential);
  } catch (err) {
    console.error(err);
    errorToast(err.message);
  }
};

export const singInWithEmailPassword = async ({ email, password }) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    errorToast(err.message);
  }
};

export const logout = async () => {
  await auth.signOut();

  setCookie(CONSENT_COOKIE_NAME, '', { days: -7 });
  setCookie(CONSENT_PREFERENCE_COOKIE_NAME, '', { days: -7 });

  if (!window.google) return console.error('[GSI Client] Script Not loaded');

  window.google.accounts.id.disableAutoSelect();

  // Display one tab login prompt after logout
  // window.google.accounts.id.prompt();

  // This will logout the user from redux store as the logout is being dispatched
  return { type: USER_ACTIONS.USER_LOGOUT };
};
export const getCurrentuser = () => app.auth().currentUser;

export const getAuthToken = () =>
  new Promise((resolve, reject) => {
    app.auth().currentUser.getIdToken().then(resolve).catch(reject);
  });

export const googleOneTap = () => {
  if (!window.google) return console.error('[GSI Client] Script Not loaded');
  if (!app.auth().currentUser) window.google.accounts.id.prompt();
};
