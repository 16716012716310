import React from 'react';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import SiteNavigation from 'components/site-navigation/SiteNavigation';
import { auth } from 'utils/Authentication';
import Loader from 'components/loader/Loader';

import 'Site.scss';
import Footer from 'components/footer/Footer';
import { isMobile } from 'utils/common';

// For react router 4/5
// export function ProtectedRoute({ component: Component, ...restOfProps }) {
//   const [user, loading, error] = useAuthState(auth);
//   if (loading) return <Loader fullScreen />;
//   return user ? (
//     <Route
//       {...restOfProps}
//       render={(props) => (
//         <div className="content">
//           <SiteNavigation location={props.location} /> <Component {...props} user={user} />
//         </div>
//       )}
//     />
//   ) : (
//     <NotLoggedIn />
//   );
// }

export const NotLoggedIn = () => {
  // Temporarily redirect user instead of loading the route
  return <Navigate to="/" />;
};

export const ProtectedRouteElement = ({ component: RouteComponent, ...passProps }) => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const navigate = useNavigate();

  if (error) return <Loader fullScreen error />;
  else if (loading) return <Loader fullScreen />;
  else if (user)
    return (
      <div className="content">
        <SiteNavigation {...passProps} location={location} navigate={navigate} />
        <RouteComponent {...passProps} location={location} navigate={navigate} />
        {isMobile() && <Footer />}
      </div>
    );

  return <NotLoggedIn />;
};
