import { useEffect, useState } from 'react';
import { getRecordingUrl } from 'utils/api';
import Loader from 'components/loader/Loader';
import './AudioPlayer.scss';

export default function AudioPlayer(props) {
  const [loading, setLoading] = useState(true);
  const [recordingUrl, setRecordingUrl] = useState();
  const [subtitleUrl, setSubtitleUrl] = useState();

  useEffect(() => {
    const getRecording = async () => {
      const objectUrl = await getRecordingUrl(props.oid, props.language, props.extension);
      setRecordingUrl(objectUrl.recordingUrl);

      // TODO-Phase2: Use it for VTT or subtitle functionality
      setSubtitleUrl(objectUrl.subtitleFileUrl);

      setLoading(false);
    };
    if (props.oid && !recordingUrl) getRecording();
  }, [props.oid]);

  return (
    <div className="audio-player">
      {loading ? <Loader inline /> : <audio ref={props.audioRef} src={recordingUrl} controls></audio>}
    </div>
  );
}
