import RecordingListItem from 'components/recording-list-item/RecordingListItem';

export default function RecordingList({ sortedRecordings }) {
    return (<section className="recording-list">
        {sortedRecordings.today.length ? (
            <div className="recording-list-set">
                <span className="set-title">Today</span>
                {sortedRecordings.today.map((value, index) => {
                    if (value.uploadDate)
                        return <RecordingListItem recording={value} key={value._id} />;
                })}
            </div>
        ) : null}
        {sortedRecordings.yesterday.length ? (
            <div className="recording-list-set">
                <span className="set-title">Yesterday</span>
                {sortedRecordings.yesterday.map((value, index) => {
                    if (value.uploadDate)
                        return <RecordingListItem recording={value} key={value._id} />;
                })}
            </div>
        ) : null}
        {sortedRecordings.earlier.length ? (
            <div className="recording-list-set">
                <span className="set-title">Earlier</span>
                {sortedRecordings.earlier.map((value, index) => {
                    if (value.uploadDate)
                        return <RecordingListItem recording={value} key={value._id} />;
                })}
            </div>
        ) : null}
    </section>)
}
