import SeagullIllustration from 'assets/images/seagull.png';
import { Link } from 'react-router-dom';

export default function EmptyRecordingList({ filter }) {
  return (<section className="empty-recording-list">
    <img src={SeagullIllustration} className='illustration monochromatic' alt="seagull walking on empty land" />
    <span className="empty-title">{!filter ? "Lots of empty space here" : "No results found"}</span>
    {
      !filter ? (<span className="empty-description">
        <Link to="/upload">Upload your recordings</Link> to get started
      </span>) :
        (<span className="empty-description">
          <Link to="/dashboard">View all your recordings</Link>
        </span>)
    }
  </section>)
}
