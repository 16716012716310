import { useAppState } from 'appstate';
import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import reactUseCookie from 'react-use-cookie';
import { udpateUserCookieConsent } from 'utils/api';
import { CONSENT_COOKIE_NAME, CONSENT_PREFERENCE_COOKIE_NAME } from 'utils/constants';

import './CookieConsentElement.scss';

const DEBUG_MODE = process.env.NODE_ENV === 'development' ? true : false;

export default function CookieConsentElement() {
  const [state] = useAppState();
  const {
    user: { user },
  } = state;
  const [consentCookie, setConsentCookie] = reactUseCookie(CONSENT_COOKIE_NAME);
  const [consentPreferenceCookie, setConsentPreferenceCookie] = reactUseCookie(
    CONSENT_PREFERENCE_COOKIE_NAME
  );
  const [cookiePreference, setCookiePreference] = useState(
    consentPreferenceCookie
      ? JSON.parse(consentPreferenceCookie)
      : {
          necessary: true,
          feedback: true,
          marketing: true,
        }
  );
  const [CookieModal, openCookieModal, closeCookieModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const updateCookieDataInDb = (consent) => udpateUserCookieConsent(consent, cookiePreference);

  useEffect(() => {
    if (user) {
      if (!Object.prototype.hasOwnProperty.call(user, 'cookieConsentPreference')) {
        if (consentCookie === 'true') updateCookieDataInDb(consentCookie === 'true');
      } else {
        const { cookieConsent, cookieConsentPreference } = user || {};
        if (cookieConsent === false) {
          setConsentCookie('', { days: -1 });
          setConsentPreferenceCookie('', { days: -1 });
          openCookieModal();
        } else if (cookieConsent === true) {
          setConsentCookie(cookieConsent);
          setConsentPreferenceCookie(JSON.stringify(cookieConsentPreference));
          closeCookieModal();
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (consentCookie === '') openCookieModal();
  }, [consentCookie]);

  const handleCheckboxChange = (type) => {
    if (type) {
      setCookiePreference({
        ...cookiePreference,
        [type]: !cookiePreference[type],
      });
    }
  };

  const handleAcceptClick = () => {
    setConsentCookie(true);
    setConsentPreferenceCookie(JSON.stringify(cookiePreference));
    if (user) {
      updateCookieDataInDb(true);
    }
    closeCookieModal();
  };

  return (
    <CookieModal>
      <div className="modal cookie-consent-modal">
        <div className="modal-title">Cookie Preferences</div>
        <div className="modal-body">
          <div className="modal-body-title">
            This website uses cookies to enhance the user experience.
          </div>
          <div className="cookie-consent-options">
            <CheckBox
              label="Necessary"
              required
              description="These are the mandatory cookies that we use to enhance your experience"
            />
            <CheckBox
              label="Feedback"
              isChecked={cookiePreference.feedback}
              onChange={handleCheckboxChange.bind(null, 'feedback')}
              description="We'd love your feedback on our product"
            />
            <CheckBox
              label="Marketing & Offers"
              isChecked={cookiePreference.marketing}
              onChange={handleCheckboxChange.bind(null, 'marketing')}
              description="Latest news and offers"
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="modal-actions">
          <button className="primary" onClick={handleAcceptClick}>
            Save Preferences
          </button>
        </div>
      </div>
    </CookieModal>
  );
}

const CheckBox = ({ label, isChecked, onChange, required, description }) => (
  <label className="checkbox-item">
    <input
      type="checkbox"
      checked={required || isChecked}
      id={label.toLowerCase()}
      onChange={onChange || preventDefaultHandler}
    />{' '}
    <b>{label}</b>: {description}
  </label>
);

const preventDefaultHandler = (e) => e.preventDefault();
