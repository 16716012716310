import { useEffect, useState } from 'react';
import { getRecordings } from 'utils/api';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

import './Dashboard.scss';
import ContentSecion from './ContentSecion';
import useFilterType from 'hooks/useFilterType';

import { isToday, isEarlier, isYesterday } from 'utils/dateTime.helper';

export default function Dashboard(props) {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [sortedRecordings, setSortedRecordings] = useState({
    today: [],
    yesterday: [],
    earlier: [],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const filter = useFilterType(props.location);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const response = await getRecordings();
      if (response) {
        setRecordings(response.recordings);
        return setLoading(false);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    let results = recordings;
    if (filter) {
      results = results.filter((recording) => recording[filter] === true);
    }
    if (searchTerm) {
      results = results.filter((recording) => recording.name.toLowerCase().includes(searchTerm));
    }
    if (!results.length) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    sortAndSetRecordings(results);
  }, [recordings, searchTerm, filter]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortAndSetRecordings = (recordings) => {
    const sortedRecordings = sortRecordings(recordings);
    setSortedRecordings(sortedRecordings);
  };

  const sortRecordings = (recordings) => {
    let sortedRecordings = {
      today: [],
      yesterday: [],
      earlier: [],
    };

    // Sort recordings by date
    recordings.sort((a, b) => {
      return a.uploadDate < b.uploadDate ? 1 : a.uploadDate > b.uploadDate ? -1 : 0;
    });

    for (let i = 0; i < recordings.length; i++) {
      const { uploadDate } = recordings[i];

      if (!uploadDate) sortedRecordings.earlier.push(recordings[i]);
      else if (isToday(uploadDate)) sortedRecordings.today.push(recordings[i]);
      else if (isYesterday(uploadDate)) sortedRecordings.yesterday.push(recordings[i]);
      else if (isEarlier(uploadDate)) sortedRecordings.earlier.push(recordings[i]);
      else sortedRecordings.earlier.push(recordings[i]);
    }

    return sortedRecordings;
  };

  return (
    <main className="dashboard">
      <section className="controls">
        <div className="title">My Recordings</div>
        <div className="search-box">
          <input
            type="text"
            className="search"
            placeholder="Search recordings..."
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <SearchIcon className="search-icon icon" />
        </div>
      </section>

      <ContentSecion
        loading={loading}
        isEmpty={isEmpty}
        filter={filter}
        sortedRecordings={sortedRecordings}
      />
    </main>
  );
}
