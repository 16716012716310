import GoogleLogo from 'assets/images/google.png';
import { errorToast } from 'utils/toast';

import './Authentication.scss';
import { useState } from 'react';
import { singInWithEmailPassword } from 'utils/Authentication';
import { Link } from 'react-router-dom';

export default function Authentication({ onGoogleClick }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onEmailChange = (event) => {
    event.stopPropagation();
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    event.stopPropagation();
    setPassword(event.target.value);
  };

  const onSignInClick = () => {
    if (!email) {
      errorToast('Please add an email id');
      return;
    }
    if (!password) {
      errorToast('Please add a password');
      return;
    }
    singInWithEmailPassword({ email, password });
  };

  return (
    <div className="authentication-container">
      <div role="button" tabIndex={0} onClick={onGoogleClick} className="authentication">
        <span className="auth-logo">
          <img src={GoogleLogo} alt="google logo" />
        </span>
        <span className="label">Sign in with Google</span>
      </div>
      <div className="or-separator">OR</div>
      <div className="email-password-container">
        <div className="input-wrapper">
          <label htmlFor="email">E-mail:</label>
          <input
            type="email"
            value={email}
            name="email"
            onChange={onEmailChange}
            placeholder="E-mail"
            required
          ></input>
        </div>
        <div className="input-wrapper">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            value={password}
            name="password"
            onChange={onPasswordChange}
            placeholder="Password"
            required
            onKeyDown={(event) => {
              if (event && event.key === 'Enter') onSignInClick();
            }}
          ></input>
        </div>
        <div className="authentication-submit-wrapper">
          <div className="authentication-terms">
            By continuing, you agree to Transcrisp's <Link to="/terms">Terms of Use</Link> and
            confirm that you have read Transcrisp's <Link to="/privacy">Privacy Policy</Link>.
          </div>
          <button onClick={onSignInClick} className="authentication-submit button primary">
            <span className="label">Sign In</span>
          </button>
        </div>
      </div>
    </div>
  );
}
