import Authentication from 'components/authentication/Authentication';
import Loader from 'components/loader/Loader';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useSearchParams } from 'react-router-dom';
import { auth, signInWithGoogleRedirect } from 'utils/Authentication';
import './Login.scss';

export default function Login() {
  const [user, loading, error] = useAuthState(auth);
  const [searchParams, setSearchParams] = useSearchParams();

  if (loading) return <Loader fullScreen />;

  return user ? (
    <Navigate to={`/dashboard${searchParams ? `?${searchParams.toString()}` : ''}`} />
  ) : (
    <React.Fragment>
      <div className="login-container">
        <div className="login-title">Get Started</div>
        <div className="login-options">
          <Authentication onGoogleClick={signInWithGoogleRedirect} />
        </div>
      </div>
      <span className="login-note">
        New user registrations and other sign-in options are coming soon!<br />Reach out to us at{' '}
        <a href="mailto:hello@transcrisp.com">hello@transcrisp.com</a> to get an early invite.
      </span>
    </React.Fragment>
  );
}
