import React from 'react';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation } from 'react-router-dom';
// import { auth } from 'utils/Authentication';

import analytics from 'utils/Analytics';
import { excludedUrlSearchParamsTrackingGA } from 'utils/constants'
export default function useGoogleAnalytics() {
  // const [user, loading, error] = useAuthState(auth);
  const location = useLocation();

  React.useEffect(() => analytics.init(), []);

  // TODOi: check for user concent, When tracking user uncomment this.
  // React.useEffect(() => {
  //   if (user) analytics.setGAUserId(user.uid)
  // }, [user]);

  React.useEffect(() => {
    const { pathname, search } = location || {};

    // For deleteing oid in search because it will be a unique url 
    const searchParams = new URLSearchParams(search);
    for (let i of excludedUrlSearchParamsTrackingGA) searchParams.delete(i);

    const currentPath = `${pathname}?${searchParams.toLocaleString()}`;
    analytics.sendPageview(currentPath);
  }, [location]);
}
