import { errorToast } from './toast';

// For bytes to MB, KB, GB conversion
const FS_EXT = ['Bytes', 'KB', 'MB', 'GB'];

export const getAudioFileDuration = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      let audioContext = new (window.AudioContext || window.webkitAudioContext)();

      audioContext.decodeAudioData(event.target.result, function (buffer) {
        resolve(buffer.duration);
      });
    };

    reader.onerror = function (event) {
      const err = `An error ocurred reading the file: ${event}`;
      errorToast(err);
      console.error(err);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getDownloadFileContent = (resData) => {
  const fileData = typeof resData === 'string' ? JSON.parse(resData) : resData;
  return fileData
    .map((speech) => {
      if (speech.start_time && speech.end_time) {
        return `Start Time: ${speech.start_time} - End Time: ${speech.end_time}\n\nSpeaker: ${speech.speaker}\n\n${speech.text}`;
      } else `${speech.speaker}: ${speech.text}`;
    })
    .join('\n\n\n');
};

export const downloadFileTranscriptionFile = (resData, title, language) => {
  // Logic for Downloading the file
  const fileData = typeof resData === 'string' ? resData : JSON.stringify(resData);
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${title}-${language}.txt`;
  link.href = url;
  link.click();
};

export const fileSize = (file) => {
  let { size } = file;

  let i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }

  // Return file size in string
  return Math.round(size * 100) / 100 + ' ' + FS_EXT[i];
};
