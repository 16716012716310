import { Link, useNavigate } from 'react-router-dom';
import SpeechBlock from 'components/speech-block/SpeechBlock';
import Paywall from 'components/paywall/Paywall';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as StarFilledIcon } from 'assets/icons/star-filled.svg';
import { ReactComponent as TrashCanIcon } from 'assets/icons/trash-can.svg';
import { ReactComponent as ChevronLeftTinyIcon } from 'assets/icons/chevron-left-tiny.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';
import { ReactComponent as ArchiveFilledIcon } from 'assets/icons/archive-filled.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import {
  getTranscriptionResults,
  getRecordingData,
  deleteDashboardData,
  updateStarred,
  updateArchived,
  updateTranscription,
  getUserData,
} from 'utils/api';
import { SHOW_TRANSLATION_COOKIE, JOB_STATUS } from 'utils/constants';
import { useEffect, useRef, useState } from 'react';
import AudioPlayer from 'components/audioPlayer/AudioPlayer';
import { formatDate, secToMin } from 'utils/dateTime.helper';
import Loader from 'components/loader/Loader';
import { useModal } from 'react-hooks-use-modal';

// import { Summary, ActionButton, SAMPLE_SPEECH_BLOCKS, SAMPLE_SUMMARY_TEXT } from './SampleBlocks'
import './Transcript.scss';
import InProgress from 'components/transcription-status/InProgress';
import Failed from 'components/transcription-status/Failed';
import { isTimeRemainingAvailable } from 'utils/user.helper';
import FreeTrial from 'components/transcription-status/FreeTrial';
import { errorToast, successToast } from 'utils/toast';
import { downloadFileTranscriptionFile, getDownloadFileContent } from 'utils/file.helper';
import { useAppState } from 'appstate';
import { USER_ACTIONS } from 'reducers/user';
import TranslateComponent from 'views/translate/Translate';
import {
  getKey,
  SUPPORTED_TRANSCRIPTION_LANGUAGES,
  SUPPORTED_TRANSLATION_LANGUAGES,
} from 'utils/languages';

export default function Transcript(props) {
  const [allValues, setAllValues] = useState({
    transcripts: [],
    stringifiedOriginalTranscripts: '[]',
    recording: {},
    loading: false,
    isUserEditing: false,
    selectedLanguage: '',
  });

  const [state, dispatch] = useAppState();
  const {
    user: { user },
  } = state;
  const { transcripts, stringifiedOriginalTranscripts, recording, loading, isUserEditing } =
    allValues;
  const [DeleteModal, openDeleteModal, closeDeleteModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  });
  const [UpdateModal, openUpdateModal, closeUpdateModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  });

  const navigate = useNavigate();

  const { search } = props.location || {};
  const oid = new URLSearchParams(search).get('oid');

  const audioRef = useRef();

  useEffect(() => {
    if (!oid) return navigate('/');

    const init = async () => {
      setAllValues({
        ...allValues,
        loading: true,
      });
      const recordingResponse = await getRecordingData(oid);

      setAllValues({
        ...allValues,
        loading: false,
        recording: recordingResponse,
        selectedLanguage: recordingResponse.language,
      });
    };

    init();
  }, []);

  useEffect(() => {
    const init = async (language = false) => {
      setAllValues({
        ...allValues,
        loading: true,
      });
      const transcriptsSesponse = await getTranscriptionResults(allValues.recording._id, language);
      setAllValues({
        ...allValues,
        transcripts: transcriptsSesponse || [],
        stringifiedOriginalTranscripts: JSON.stringify(transcriptsSesponse || []),
        loading: false,
      });
    };

    if (allValues.selectedLanguage) init(allValues.selectedLanguage);
  }, [allValues.selectedLanguage]);

  useEffect(() => {
    setAllValues({
      ...allValues,
      isUserEditing: !(
        !transcripts.length || JSON.stringify(transcripts) === stringifiedOriginalTranscripts
      ),
    });
  }, [transcripts, stringifiedOriginalTranscripts]);

  const responseUpdateHandler = async () => {
    try {
      const recordingResponse = await getRecordingData(allValues.recording._id);
      if (recordingResponse.jobStatus === JOB_STATUS.COMPLETED) {
        const transcriptsSesponse = await getTranscriptionResults(
          allValues.recording._id,
          allValues.recording.language
        );
        setAllValues({
          ...allValues,
          transcripts: transcriptsSesponse || [],
          stringifiedOriginalTranscripts: JSON.stringify(transcriptsSesponse || []),
          recording: recordingResponse,
          selectedLanguage: recordingResponse.language,
        });
        // Update consumedTime in dashboard header
        const { consumedTime } = await getUserData();
        if (consumedTime) {
          dispatch({ type: USER_ACTIONS.USER_UPDATE_SUCCESS, data: { consumedTime } });
        }
        return;
      } else if (recordingResponse.jobStatus === JOB_STATUS.FAILED) {
        return setAllValues({
          ...allValues,
          recording: recordingResponse,
          selectedLanguage: recordingResponse.language,
        });
      }
    } catch (err) {
      console.error(err);
      return;
    }
  };

  const handleDeleteButton = async () => {
    await deleteDashboardData(allValues.recording._id);
    closeDeleteModal();
    navigate('/dashboard');
  };

  const handleUpdateButton = async () => {
    const response = await updateTranscription(
      allValues.recording._id,
      allValues.selectedLanguage,
      transcripts
    );
    closeUpdateModal();
    if (response && response.success) {
      setAllValues({
        ...allValues,
        stringifiedOriginalTranscripts: JSON.stringify(transcripts),
      });
      successToast('Transcripts updated successfully.');
    } else {
      errorToast();
      console.error('Error While Updating Transcript', response);
    }
  };

  const handleCancelUpdate = async () => {
    // Rever change to original transcripts
    setAllValues({
      ...allValues,
      transcripts: JSON.parse(stringifiedOriginalTranscripts),
    });
    closeUpdateModal();
  };

  const handleCancelUpdateModal = async () => {
    closeUpdateModal();
  };

  const handleManualSubmitSuccess = () => {
    setAllValues({
      ...allValues,
      recording: {
        ...recording,
        jobStatus: JOB_STATUS.IN_PROGRESS,
      },
    });
  };

  const handleTranslate = async (destinationLanguage) => {
    const recordingResponse = await getRecordingData(allValues.recording._id);

    setAllValues({
      ...allValues,
      loading: false,
      recording: recordingResponse,
      selectedLanguage: destinationLanguage,
    });
  };

  const toggleStarRecording = async () => {
    const response = await updateStarred(allValues.recording._id, !recording.starred);
    if (response && response.status === 'OK') {
      setAllValues({
        ...allValues,
        recording: {
          ...recording,
          starred: !recording.starred,
        },
      });
    }
  };

  const toggleArchiveRecording = async () => {
    const response = await updateArchived(allValues.recording._id, !recording.archived);
    if (response && response.status === 'OK') {
      setAllValues({
        ...allValues,
        recording: {
          ...recording,
          archived: !recording.archived,
        },
      });
    }
  };

  const onSpeakerNameBlur = (oldSpeaker, newSpeaker) => {
    if (newSpeaker && newSpeaker !== oldSpeaker) {
      // Update all the speakers of transcripts belonging to oldSpeaker and call api to update the transcripts
      const updatedTranscripts = transcripts.map((speech) => {
        if (speech.speaker === oldSpeaker && speech.speaker !== newSpeaker) {
          return {
            ...speech,
            speaker: newSpeaker,
          };
        }
        return speech;
      });
      setAllValues({
        ...allValues,
        transcripts: updatedTranscripts,
      });
    } else {
      setAllValues({
        ...allValues,
        transcripts,
      });
    }
  };

  const onSpeechBlur = (index, newSpeech) => {
    if (newSpeech) {
      const updatedTranscripts = [...transcripts];
      const transcriptLocation = updatedTranscripts[index];
      if (transcriptLocation && transcriptLocation.text !== newSpeech) {
        updatedTranscripts[index].text = newSpeech;
        setAllValues({
          ...allValues,
          transcripts: updatedTranscripts,
        });
      }
    }
  };

  const onTimeClick = (time) => {
    if (audioRef && audioRef.current) {
      audioRef.current.play();
      audioRef.current.currentTime = Math.round(time);
    }
  };

  const renderLangDropdown = () => {
    const supportedLanguages = [
      {
        key: recording.language,
        value: getKey(SUPPORTED_TRANSCRIPTION_LANGUAGES, recording.language),
      },
    ];

    if (recording.translatedLanguages) {
      recording.translatedLanguages.map((key) => {
        supportedLanguages.push({
          key,
          value: getKey(SUPPORTED_TRANSLATION_LANGUAGES, key),
        });
      });
    }

    return (
      <select
        name="transcription-language"
        value={allValues.selectedLanguage}
        onChange={(event) => {
          setAllValues({
            ...allValues,
            selectedLanguage: event.target.value,
          });
        }}
        disabled={supportedLanguages.length <= 1}
      >
        {supportedLanguages.map((obj) => (
          <option key={`source-${obj.key}`} value={obj.key}>
            {obj.value}
          </option>
        ))}
      </select>
    );
  };

  const TranscriptsElement = () => {
    if (recording.jobStatus == JOB_STATUS.COMPLETED) {
      if (transcripts && transcripts.length) {
        return (
          <div className="transcription-copy">
            <div className="title-button-wrapper">
              <div className="section-title">
                {`Transcript in `}
                {renderLangDropdown()}
              </div>
              {!isUserEditing && (
                <div className="edit-helper">
                  &#9888;&nbsp; Click on any text below to start making corrections
                </div>
              )}
              {isUserEditing && (
                <div className="button-container">
                  <button className="button primary" onClick={openUpdateModal}>
                    Update
                  </button>
                  <button className="button tertiary" onClick={handleCancelUpdate}>
                    Reset
                  </button>
                </div>
              )}
            </div>
            {transcripts.map((speech, index) => (
              <SpeechBlock
                speech={speech.text}
                speaker={speech.speaker}
                key={index}
                onSpeakerNameBlur={onSpeakerNameBlur.bind(null, speech.speaker)}
                onSpeechBlur={onSpeechBlur.bind(null, index)}
                start_time={speech.start_time}
                end_time={speech.end_time}
                onTimeClick={onTimeClick}
              />
            ))}
            {/* {SAMPLE_SPEECH_BLOCKS.map((SpeechBlock) => SpeechBlock)} */}
          </div>
        );
      } else {
        // If the job is completed by the transcription are not found
        // Show a message with a contact button so user can reach out to us.
        // This is an issue from our side if this case happens.
        return <Failed />;
      }
    } else if (recording.jobStatus === JOB_STATUS.IN_PROGRESS) {
      return <InProgress onCheckStatus={responseUpdateHandler} />;
    } else if (recording.jobStatus === JOB_STATUS.FAILED) {
      // If this happens that means there is an error, the user should reach out to us
      // We can check and update the user on the issue
      return <Failed />;
    } else if (recording.jobStatus === JOB_STATUS.NOT_SUBMITTED) {
      return isTimeRemainingAvailable(user, recording) ? (
        <FreeTrial recording={recording} onSuccess={handleManualSubmitSuccess} />
      ) : (
        <Paywall
          duration={recording.duration}
          paymentDetails={recording.paymentDetails}
          oid={allValues.recording._id}
        />
      );
    }

    // If non of the cases are availeble, show a loader
    return <Loader />;
  };

  return (
    <main className="transcript">
      <section className="controls">
        <Link to="/" className="back-button">
          <ChevronLeftTinyIcon className="back-icon icon" />
          <span className="label">Back</span>
        </Link>
      </section>

      <section className="transcript-content">
        {!loading && recording && recording.name ? (
          <>
            <div className="details">
              <div className="title">{recording.name}</div>
              <div className="information">
                <div className="metadata">
                  <span className="datetime">{formatDate(recording.uploadDate)}</span>
                  <span className="duration">({secToMin(recording.duration)} min)</span>
                </div>
                <div className="actions">
                  {transcripts && transcripts.length ? (
                    <div
                      className="action-download action-item"
                      onClick={() =>
                        downloadFileTranscriptionFile(
                          getDownloadFileContent(stringifiedOriginalTranscripts),
                          recording.name,
                          allValues.selectedLanguage
                        )
                      }
                    >
                      <DownloadIcon className="download-icon icon" />
                      <span className="download">Download</span>
                    </div>
                  ) : null}
                  <div className="action-star action-item" onClick={toggleStarRecording}>
                    {recording.starred ? (
                      <StarFilledIcon className="star-filled-icon icon" />
                    ) : (
                      <StarIcon className="star-icon icon" />
                    )}
                    <span className="star">{recording.starred ? 'Remove star' : 'Star'}</span>
                  </div>
                  <div className="action-archive action-item" onClick={toggleArchiveRecording}>
                    {recording.archived ? (
                      <ArchiveFilledIcon className="archive-filled-icon icon" />
                    ) : (
                      <ArchiveIcon className="archive-icon icon" />
                    )}
                    <span className="archive">
                      {recording.archived ? 'Remove archive' : 'Archive'}
                    </span>
                  </div>
                  <div className="action-delete action-item" onClick={openDeleteModal}>
                    <TrashCanIcon className="trash-icon icon" />
                    <span className="delete">Delete</span>
                  </div>
                </div>
              </div>
              <DeleteModal>
                <div className="modal">
                  <h1 className="modal-title">Delete Permanently?</h1>
                  <p className="modal-body">
                    This will delete the audio recording and it"s transcript completely.
                  </p>
                  <div className="divider"></div>
                  <div className="modal-actions">
                    <button className="tertiary" onClick={closeDeleteModal}>
                      Cancel
                    </button>
                    <button className="primary" onClick={handleDeleteButton}>
                      Delete
                    </button>
                  </div>
                </div>
              </DeleteModal>
              <UpdateModal>
                <div className="modal">
                  <h1 className="modal-title">Update Transcripts?</h1>
                  <p className="modal-body">
                    This will update the transcripts permanently and the action cannot be undone.
                    Are you sure you want to update it?
                  </p>
                  <div className="divider"></div>
                  <div className="modal-actions">
                    <button className="tertiary" onClick={handleCancelUpdateModal}>
                      Recheck
                    </button>
                    <button className="primary" onClick={handleUpdateButton}>
                      Update
                    </button>
                  </div>
                </div>
              </UpdateModal>
            </div>
            {/* Summary Section */}
            {/* <Summary summary={SAMPLE_SUMMARY_TEXT} /> */}
            <AudioPlayer
              oid={allValues.recording._id}
              language={allValues.recording.language}
              extension={allValues.recording.extension}
              audioRef={audioRef}
            />
            {/* <TranslateComponent
              location={props.location}
              recordingLanguage={recording.language}
              handleTranslate={handleTranslate}
            /> */}
            <TranscriptsElement />
            {/* Action button */}
            {/* <div style={{ width: "30%', margin: '5px' }}>
              <ActionButton recording={recording} oid={oid} />
            </div> */}
          </>
        ) : (
          <Loader />
        )}
      </section>
    </main>
  );
}
