import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { auth } from 'utils/Authentication';
import Loader from 'components/loader/Loader';
import './LandingPage.scss';
import Footer from 'components/footer/Footer';
import { PAYMENT_OPTIONS } from 'utils/constants';

export const LandingPage = () => {
  const [user, loading, error] = useAuthState(auth);
  if (loading) return <Loader fullScreen />;
  return user ? (
    <Navigate to="/dashboard" />
  ) : (
    <div className="landing-page">
      <div className="landing-page-wrapper">
        <div className="hero">
          <span className="eyebrow">AI-powered audio to text transcription</span>
          <h1 className="heading">
            Because we are
            <br />
            crisp at transcribing.
          </h1>
          <p className="description">
            Transcrisp provides fast and accurate audio to text conversion for podcasts, recorded
            meetings, online courses etc.
          </p>
          <div className="call-to-action">
            <Link to="/login" className="button primary">
              Get Started for Free<div className="button-helper">(No CC Required)</div>
            </Link>
            <div className="cta-meta">
              <span className="offer">First 60 minutes free</span>
              <span className="default">
                USD {PAYMENT_OPTIONS.US.PRICE_PER_DURATION} per 60 minutes thereafter
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
